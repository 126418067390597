import FooterPartenerCommun from "../commun/FooterPartenerCommun";
import Header from "../commun/Header";

export default function(){

    return(
        <div className="dashboard">
            <Header/>
            <div className="container">
            <h4 className="text-center mt-5  w-custom">
                Plenary Meeting, Brussels
                21-23 June 2024
            </h4>
                <div className="row mt-3">
                    <div className="col col-12 p-3">
                        <div className="card-blue c-p" onClick={()=>{window.location.href = '/program'}}>
                            <h4 className="mt-5">
                                Program
                            </h4>
                        </div>
                    </div>
                    <div className="col col-6 p-3">
                        <div className="card-blue c-p" onClick={()=>{window.location.href = '/list'}}>
                            <h4 className="mt-custom" style={{marginTop: '30%'}}>
                                Participant list
                            </h4>
                        </div>
                    </div>
                    <div className="col col-6 p-3">
                        <div className="card-blue c-p" onClick={()=>{window.location.href = '/speakers'}}>
                            <h4 className="mt-5">
                                Speakers
                            </h4>
                        </div>
                    </div>
                    <div className="col col-6 p-3">
                        <div className="card-blue c-p" onClick={()=>{window.location.href = '/contact'}}>
                            <h4 className="mt-5">
                                Contact
                            </h4>
                        </div>
                    </div>
                    <div className="col col-6 p-3">
                        <div className="card-blue c-p" onClick={()=>{window.location.href = '/partners'}}>
                            <h4 className="mt-custom" style={{marginTop: '30%'}}>
                                Partner
                                program
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPartenerCommun/>
        </div>
    )
}