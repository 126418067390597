import { useState } from "react"
import FooterPartener from "../commun/FooterPartener";
import { setUserCookies } from "../../utils/user";

export default function(){

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');

    const log = ()=>{
        if(login === 'plenarymeeting' && password == 'Brussels2024'){
            setUserCookies();
            window.location.href= '/dashboard';
        }else{
            setMsg('Error: invalid login');
        }
    }

    return(
        <div className="login-page">
            <div className="container">
                <div className="form-login position-absolute top-50 start-50 translate-middle text-center c-white">
                    <img src="./assets/title-login.png" width={'100%'} height={'auto'} />
                    <h1>Login</h1>
                    <input className="input-control" type="text" placeholder="Login" value={login} onChange={(e)=>{setLogin(e.target.value)}} />
                    <input className="input-control" type="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    <br />
                    <p className="err mt-3">
                        {msg}
                    </p>
                    <button onClick={()=>{log()}} className="btn-control">
                        Enter
                    </button>
                    <p className="mt-5">
                        Plenary Meeting, Brussels
                        <br />
                        21-23 June 2024
                    </p>
                </div>
            </div>
            <FooterPartener/>
        </div>

    )
}