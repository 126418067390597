import FooterPartenerCommun from "../commun/FooterPartenerCommun";
import Header from "../commun/Header";
import ContactCard from "./ContactCard";

export default function(){

    const contacts = [
        {
            continent: "North American Group",
            name: "Dr. Tina J. Park",
            num: {
                numero: "+1 416 828 2361",
                more: "(whatsapp/call/signal)",
            },
            mails:[
                'tpark@trilateral.org ',
            ],
        },
        {
            continent: "European Group",
            name: "Ms. Yulia Loeva ",
            num: {
                numero:"+49 (178) 1494276",
                more: "(whatsapp/call/signal)",
            },
            mails:[
                'loeva@dgap.org',
            ],
        },
        {
            continent: "Asia Pacific Group ",
            name: "Ms. Hifumi Tajima",
            num:{
                numero:"+81-90-2200-1575",
                more:"",
            },
            mails:[
                'tajima@jcie.or.jp',
                'pat@jcie.or.jp',
            ],
        },
    ]

    return(
        <div className="dashboard">
            <Header back={true}/>
            <div className="container">
                <h1 className="text-center mt-5">Contact</h1>
                <div className="row mt-3 mb-5">
                    {
                        contacts.map((contact, index)=>{
                            return(
                                <div key={index} className="col col-12 p-3">
                                    <ContactCard contact={contact}/>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
            <FooterPartenerCommun/>
        </div>
    )
}