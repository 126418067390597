import BtnReturn from "./BtnReturn";

export default function({back}){

    return(
        <header className="header text-center">
            {
                (back) ? <BtnReturn/> : null
            }
            <img src="./assets/title-login.png" width={'70%'} height={'auto'} />
        </header>
    )
}