import FooterPartenerCommun from "../commun/FooterPartenerCommun"
import Header from "../commun/Header"

export default function(){

    return(
        <div className="dashboard">
            <Header back={true}/>
            <div className="text-center c-light-blue mt-5">
                <h5>
                    PLENARY SESSION
                </h5>
                <h1 className="t-bold">
                    PARTNER PROGRAM
                </h1>
                <h6>
                    Saturday 22 June 2024
                </h6>
            </div>
            <div className="container">
                <div className="row container-car mt-5 mb-5">
                    <div className="col col-4 p-1 car">
                        <img className="img-car" src="./assets/partners/car-1.jpg" width={'100%'} height={'100%'} alt="" />
                    </div>
                    <div className="col col-5 p-1 car">
                        <img className="img-car" src="./assets/partners/car-2.jpg" width={'100%'} height={'100%'} alt="" />
                    </div>
                    <div className="col col-3 p-1 car">
                        <img className="img-car" src="./assets/partners/car-3.jpg" width={'100%'} height={'100%'} alt="" />
                    </div>
                </div>
            </div>
            <div className="text-center">
                <h4>
                    Discover the Art Nouveau Jewels of Brussels and visit of the Musée Magritte
                </h4>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col col-12">
                        <p>
                            Art Nouveau was born in the Avenue Louise district of
                            Brussels. From Victor Horta’s Hotel a veritable manifesto
                            of Art Nouveau, to the more geometric designs of Paul
                            Hankar, this tour explores the background to the birth
                            of this style in Brussels. You’ll discover some historic and
                            sometimes spectacular façades. 
                        </p>
                        <p className="mt-5">
                            <span className="hours p-1">09h00</span><br/>
                            <br />
                            <span className="partners-title">
                                MEETING IN THE LOBBY OF THE HOTEL FOR
                                DEPARTURE.
                            </span>
                            <br />
                                This tour will include a visit to the former
                                home and workshop of architect Victor Horta, now the
                                Horta Museum or to the Max Hallet House, one of Victor
                                Horta’s masterpieces.
                        </p>
                        <p className="mt-5">
                            <span className="partners-title">
                                THE HORTA MUSEUM
                            </span>
                            <br />
                                is housed in the house and studio
                                of the architect Victor Horta. Both buildings are typical
                                of the height of Art Nouveau. The interior of the house
                                has been largely preserved: mosaics, stained-glass
                                windows, furniture, wall paintings form a harmonious
                                whole that shows subtlety down to the smallest detail.
                                The museum is also a centre for research on Victor Horta
                                and Art Nouveau.
                        </p>
                        <p className="mt-5">
                            <span className="partners-title">
                                MAX HALLET HOUSE
                            </span>
                            <br />
                                House dating from 1904, built according to the plans
                                of V. Horta and offering a symphony of colours, whorls
                                and lights to lift the heart and please the eyes. The
                                lawyer Max Hallet had it built to receive his friends and
                                clients in a magnificent setting. Victor Horta built it in the
                                image of its occupant, laying out rooms for living and
                                areas for receiving visitors.
                        </p>
                        <p className="mt-5">
                            <span className="hours p-1">12h30</span><br/>
                            <br />
                            <span className="partners-title">
                                LUNCH AT KBC HOUSE
                            </span>
                            <br />
                                located on the majestuous
                                Grand Place of Brussels which is considered as one of the
                                world’s most beautiful squares and has been a UNESCO
                                World Heritage Site since 1998.
                                <br />
                                <br />
                                On the way to the museum, the guides will give you an
                                overview of the key monuments around the Grand Place.
                        </p>
                        <p className="mt-5">
                            <span className="hours p-1">14h00</span><br/>
                            <br />
                            <span className="partners-title">
                                VISIT OF THE MAGRITTE MUSEUM
                            </span>
                            <br />
                                The Musée Magritte Museum, located in the heart of
                                Brussels, brings together the world’s largest collection of
                                the renowned surrealist artist René Magritte: 230 works
                                and archives are displayed. The multidisciplinary space
                                houses paintings, gouaches, drawings, sculptures and
                                painted objects, as well as advertising posters, musical
                                scores, photographs and films. The Museum also has the
                                most important collection from the artist’s «vache» period.
                                <br />
                                <br />
                                The selection of works exhibited in the Royal Museums
                                of Fine Arts of Belgium is constantly expanding with a
                                changing selection of external loans. With the support of
                                Belgian and foreign museums as well as private collectors,
                                works are being made available that have previously
                                never been exhibited. The visitor can thus discover many
                                treasures that are still in private possession.
                                <br />
                                <br />
                                The Musée Magritte Museum has already received 8
                                nominations and prizes. Every year more than 300,000
                                visitors come here for all over the world to discover the
                                life and the works of René Magritte.
                        </p>
                    </div>
                </div>
            </div>
            <FooterPartenerCommun/>
        </div>
    )
}