import { useState } from "react"
import QRCode from 'qrcode.react';

export default function({contact}){

    const [view, setView] = useState(false);

    return(
            <div className="card-contact p-3 text-center">
                <h2>{contact.name}</h2>
                <hr />
                <h3>{contact.continent}</h3>
                {
                    (view)?
                    <>
                        <hr />
                        <h4>{contact.num.numero}</h4>
                        <h4>{contact.num.more}</h4>
                        <button className="btn-view" onClick={()=>{telechargerContact(contact)}}>Add</button>
                        <hr />
                        <div className="emails-container">
                            <h4 className="mb-3">
                                Email(s):
                            </h4>
                            {
                                contact.mails.map((mail, index)=>{
                                    return(
                                        <div key={index}>
                                            <h5>
                                                {mail}
                                            </h5>
                                            <button className="btn-view" onClick={()=>{window.location.href = `mailto:${mail}?subject=Trilateral Question&body=My question...`}}>Send</button>
                                            <hr />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                    :
                    ''
                }
                <button className="btn-view" onClick={()=>{setView(!view)}}>{(view) ? 'Hide' : 'View'}</button>
            </div>
        )
}

function telechargerContact(contact) {
    // Numéro de téléphone
    var numeroTelephone = contact.num.numero;
    // Nom du contact
    var nomContact = contact.name;

    // Création du contenu du fichier vCard
    var contenuVCard = "BEGIN:VCARD\n"
        + "VERSION:3.0\n"
        + "FN:" + nomContact + "\n"
        + "TEL:" + numeroTelephone + "\n"
        + "END:VCARD";

    // Création d'un objet Blob contenant le contenu
    var blob = new Blob([contenuVCard], { type: 'text/vcard' });

    // Création d'un objet URL pour le Blob
    var url = window.URL.createObjectURL(blob);

    // Création d'un élément d'ancrage pour le téléchargement
    var a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = nomContact + ".vcf";

    // Ajout de l'élément d'ancrage au DOM et déclenchement du téléchargement
    document.body.appendChild(a);
    a.click();

    // Nettoyage après le téléchargement
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}