import FooterPartenerCommun from "../commun/FooterPartenerCommun";
import Header from "../commun/Header";
import { useState, useEffect } from "react";
import Papa from 'papaparse';

export default function(){

    const [data, setData] = useState([]);
    const [loading, setLoding] = useState(true);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
        const response = await fetch('/assets/participants/participants.csv'); // Chemin relatif au répertoire public
        const reader = response.body.getReader();
        const result = await reader.read(); // Lire le fichier
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value); // Convertir en texte
        Papa.parse(csv, {
            header: true,
            complete: (result) => {
                setData(result.data);
            },
            });
        };
        fetchData();
        setTimeout(()=>{
            setLoding(false);
        }, 600);
    }, []);

    return(
        <div className="dashboard">
            <Header back={true}/>
            <h1 className="text-center mt-5">List of participants</h1>
            <div className="container">
                <input onChange={(e)=>{setSearch(e.target.value)}} value={search} placeholder="Search" type="search" className="input-control" />
            </div>
            {(loading) ?
            <h2 className="mt-5 text-center">Loading...</h2>
            :
            <div className="container mt-5">
                <div className="mb-3 t-bold">
                        <div className={"row bg-l row-list"}>
                        <div className={'col col-2 p-1'}>Last name, first name</div>
                        <div className={'col col-4 p-1'}>Organization </div>
                        <div className={'col col-2 p-1'}>Professional Title </div>
                        <div className={'col col-2 p-1'}>Country - Region</div>
                        <div className={'col col-2 p-1'}>Status</div>
                    </div>
                </div>
                {
                    data.map((row, index) => {
                        return(
                            (verifyContentWithSearch(row, search))?
                                <div className={(index % 2) ? "row bg-l row-list" : 'row bg-d row-list'} style={{fontSize: '0.5em'}} key={index}>
                                {Object.values(row).map((value, i) => (
                                    (i !== 1) ? <div className={(i !== 2) ? 'col col-2 p-1' : 'col col-4 p-1'} key={i}>{(i === 0 && i !== 1) ? row['LAST NAME'] + ' ' + row['FIRST NAME'] : (i === 4) ? row['COUNTRY'] + ' ' + row['REGION'] : (i === 5) ? row['STATUS'] : (i === 6) ? '' : value}</div> : ''
                                ))}
                                </div>
                            : 
                            ''
                        )
                    })
                }
            </div>
            }
            <FooterPartenerCommun/>
        </div>
    )
}

function verifyContentWithSearch(row, search){
    let rep =  false
    Object.values(row).map((value) => {
        console.log({search});
        if(value.toLowerCase().includes(search.toLowerCase())){
            rep = true
            return true;
        }
    })

    return rep;
}