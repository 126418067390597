import { useEffect, useState } from "react";
import Header from "../commun/Header";
import FooterPartenerCommun from '../commun/FooterPartenerCommun'

export default function(){

    const [speaker, setSpeaker] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false);
        }, 1400);
    },[]);

    const speakers = [
        {
            name: "His Majesty the King of the Belgians",
            description: `His Majesty the King was born on 15 April 1960 in Brussels, the eldest son of King Albert II and Queen Paola.

            The King is the first Crown Prince not to receive private education and to follow a classical and bilingual educational path in primary and secondary schools in Belgium. In 1978, as Prince Philippe, he began his university studies at the Royal Military Academy. Interested in aerospace since childhood, he chose to join the Air Force, where he qualified as a fighter pilot. He completed his military training with a certification as a parachutist and a commando.
            
            He then continued his studies abroad. After a trimester at Oxford University in the United Kingdom and two years at Stanford University in the United States, he obtained a master's in Political Science.
            
            In 1993, he took over the Honorary Presidency of the Belgian Foreign Trade Agency (BFTA), and over the next 20 years led no fewer than 85 economic missions. He forged links between Belgian and foreign companies, and among Belgian companies themselves. After his accession to the throne in 2013, he remained Honorary President of the BFTA, and asked his sister, Princess Astrid, to represent him on economic missions.
              
            He also actively supports dialogue among the country's communities. With the establishment of the Prince Philippe Fund in 1998, he helped to promote dialogue among citizens of different language communities and to encourage greater mutual recognition and respect for everyone's individuality and culture.
            
            On 3 July 2013, King Albert II announced his abdication. On the National Day, which follows a few weeks later, Prince Philippe took the oath and became the seventh King of the Belgians. His daughter Elisabeth, who turned 22 on 25 October 2023, is now first in line to the throne.`,
        },
        {
            name: "Kofi Adjepong Boeteng ",
            description: `Kofi Adjepong-Boateng is based at King’s College, University of Cambridge. He is a Research Associate at the Centre for Financial History at Cambridge and a Trustee of the UK’s Royal Economic Society. He also serves on the Council of Management of the UK’s National Institute of Economic and Social Research (“NIESR”). Kofi is a past Trustee of the School of African and Oriental Studies (SOAS), University of London (2015-2020) and, until April 2018, he chaired the Policy Committee of the Centre for the Study of African Economies at the University of Oxford. Previously, he held visiting positions at St. Antony's College (2008-2010), and Blavatnik School of Government (2016-2018), both of which are part of the University of Oxford. 
            Aside from his academic interests, Kofi is a merchant banker by background. and co-founded the First Africa Group in 1995 as a joint venture with S.G. Warburg & Co. Between 2006 and 2009, Standard Chartered Bank Group built a shareholding in First Africa and eventually acquired the firm. Thereafter, Kofi joined Standard Chartered as a Vice Chairman. He stepped down from Standard Chartered in 2012.
            At present, Kofi serves on the boards of Pembani Remgro Infrastructure Managers, based in Stellenbosch and Johannesburg; and Helios Fairfax Partners Corporation, listed on the Toronto Stock Exchange. He is a past member of the investment committee which oversees the endowments of King’s College, Cambridge and was previously chair of the investment committee of Soros Economic Development Fund (2018-2023). Previously, Kofi was also the founding independent director on the board of Tana Africa Capital Ltd, the investment company jointly owned by Temasek and South Africa’s Oppenheimer family. He stepped down from this position in December 2012. He also served for 5 years on the board of ACE South Africa Ltd. (now Chubb Ltd), and on the ACE Group’s global International Advisory Board. He stepped down from these positions in 2012 and 2013, respectively. 
            
            In the field of public policy, Kofi served on the Africa Policy Advisory Panel, an advisory group mandated by the U.S. Congress (2003-2004), which reported to the late Colin Powell, the then U.S. Secretary of State. He also served on the Steering Committee of a research project established by the late Judge HE James Crawford at the University of Cambridge’s Lauterpacht Centre for International Law (2007-2010), which investigated questions of international law and regulation relating to accusations of corporate complicity in Human Rights abuses.
            Kofi has contributed articles to the Financial Times (London), Business and Financial Times (Accra) and Business Day (Johannesburg). His is also a contributor to academic journals including The Historical Journal and International Review of Applied Economics.
            In October 2020, Kofi was recognized in Queen Elizabeth’s birthday honours and awarded a CBE for his contribution to philanthropy.`,
        },
        {
            name: "Magdalena Andersson",
            description: `is the leader of the opposition in the Swedish parliament, and the leader of the Swedish Social Democratic Party. From 2021 to 2022 she was the Prime Minister of Sweden, the first woman to hold the position. Under her leadership as prime minister, the Swedish government took the decision to send weapons to support Ukraine against Russia’s aggression—which was the first time Sweden sent weapons to a war zone since the Soviet Union attacked Finland in 1939—and to scale up Swedish defence spending as soon as practically possible to 2 percent of GDP. It was also under the leadership of Andersson that Sweden, together with Finland, took the decision to apply for NATO membership. For this decision she was awarded the 11th Atlantic Council Global Citizen award, as well as the Ewald von Kleist Award at the Munich Security Conference 2023. 
            Andersson started her career as a Political Advisor to Prime Minister Göran Persson in 1996. She subsequently held positions as the Director of Planning for the Prime Minister, as State Secretary responsible for tax policy at the Ministry of Finance, and as a Deputy Director-General at the Swedish Tax Agency. Following the election of 2014, she assumed the role as Minister for Finance. During seven years in that position, she took Sweden’s public finances from deficit to surplus, she dealt with the effects in Sweden of the 2015 migrant crisis in Europe, as well as the COVID-19 pandemic. In 2020, Andersson was elected chair of the IMFC, and was the first woman to hold that position. 
            Andersson holds a M.Sc. in Business Administration and Economics from the Stockholm School of Economics. She has also been a Visiting Researcher in Economics at the Vienna Institute for Advanced Studies and at Harvard University. 
            Andersson is married with two children. In her spare time, she enjoys hiking and picking cloudberries in the Swedish mountains. She also enjoys hiking elsewhere, such as in the Alps and in Utah. In wintertime, she enjoys doing the same thing, but with cross country skis under her feet.`,
        },
        {
            name: "Annet Aris",
            description: `Annet Aris is Senior Affiliate Professor of Strategy at INSEAD and the Academic director of INSEAD’s Corporate Governance Centre. She focuses on digital transformation and disruption and its impact on society, industries and companies. Annet has also more than 20 years’ experience as a non-executive board member of a variety publicly listed companies across Europe. Currently she is the vice-chair of the board of ASML NV (lithography systems for semi-conductor production) and serves on the boards of Randstad NV (HR services) and Jungheinrich AG (intralogistics). Annet ranks in the top 25 most influential corporate directors in The Netherlands. Annet is a Dutch native and received a MSc. from the University of Wageningen and an MBA from INSEAD. She worked for McKinsey from 1985 until 2003, at first in the Netherlands and in the UK and, since 1990, in Germany. She was elected partner in 1994 and co-led McKinsey’s German Media Practice from 1999-2003 working on a wide array of topics. She is the author of a large number of articles and case studies on digital transformation. Together with Jacques Bughin, she wrote the book Managing Media Companies, Harnessing Creative Value. She recently published “Wegwijzer in het digitale doolhof” (“Pathway through the digital labyrinth”).`,
        },
        {
            name: "Indrani Bagchi",
            description: `She was Associate Editor with The Times of India, where she reported and analyzed foreign policy issues for the newspaper
            from 2004 until 2022.
            As Diplomatic Editor, Indrani covered the Ministry of External Affairs (MEA) on her news beat, as well as interpreted and analyzed global
            trends with an Indian perspective. Earlier, Indrani worked with India Today, a premier news magazine, The Economic Times and The
            Statesman.
            She graduated from Loreto College, Calcutta University with English honours. Indrani was a Reuters Fellow at Oxford University in 1997.
            In 2010, Indrani was awarded the Chang Lin-Tien fellowship by the Asia Foundation to study US-China relations at Brookings Institution,
            Washington DC. She is a Fellow of the Kamalnayan Bajaj Fellowship Class 3 of the Ananta Aspen Centre and a member of Aspen Global
            Leadership Network.`,
        },
        {
            name: "Rob Bauer",
            description: `Admiral Rob Bauer (Royal Netherlands Navy) is the 33rd Chair of the Military Committee of the North Atlantic Treaty Organization (NATO). As the Military Adviser to the Secretary General and the North Atlantic Council, Admiral Bauer is NATO’s most senior military officer. 
            He is the conduit through which advice from NATO’s Allied Chiefs of Defence is presented to the political decision-making bodies; and guidance and directives are issued to the Supreme Allied Commander Europe, Supreme Allied Commander Transformation and the Director General of the International Military Staff.
            
            ‘Expect the unexpected’ is for Admiral Bauer a personal mantra, as well as a sacred principle for every military force. In this time of political power shifts and increasingly complex security threats, he firmly believes that the strength of the North Atlantic Alliance lies in its cohesion. Admiral Bauer strives to be a catalyst between NATO’s military leaders, and unifying north, south, east and west, large and small. 
            Rob Bauer (Amsterdam, 1962) was commissioned as an officer in the Royal Netherlands Navy in 1984. 
            From 2005 until 2007, Bauer commanded the Air Defence and Command Frigate HNLMS De Ruyter. His command included a deployment in the Mediterranean with the Standing NATO Maritime Group 2 as part of the NATO Response Force (Operation Active Endeavour). Late 2006, Bauer was deployed to Bahrain as Deputy Commander of Task Force 150 (Operation Enduring Freedom). In 2010 and 2011, he held command of the Landing Platform Dock HNLMS Johan de Witt.
            
            As Director of Plans (2012-2015), Bauer was responsible for directing strategies relating to the future of Defence, operational policy and innovation, and the organization and structure of the armed forces as a whole, including the creation of the new Defence Cyber Command. As Vice Chief of Defence (2015-2017), Bauer oversaw the move towards more flexible and sustainable cooperation with private companies and organisations. 
            As Chief of Defence (2017-2021), he commanded the Netherlands Armed Forces during the first four years of substantial investments after a decades-long period of cutbacks. Transforming the organization required determination, strategic foresight and the ability to rebuild trust in and amongst the Armed Forces. 
            
            Rob is married to Maaike. They have three children.`,
        },
        {
            name: "Dick Benschop",
            description: `Dick Benschop is a former President & CEO of the Royal Schiphol Group. He is a non-executive member of the Board of Directors of
            Brisbane Airport Corporation PTY Ltd.
            Dick was born in Driebergen-Rijsenburg in The Netherlands. After studying History at the VU in Amsterdam, he held various positions
            in the House of Representatives and within the Labour Party. He led his own consultancy firm in 1994, before becoming active again
            in politics as deputy minister for Foreign Affairs in the second Kok cabinet (1998-2002).
            He then joined Shell where he held various positions, including VP Shell Malaysia Gas&Power in Kuala Lumpur. In 2009 he was
            appointed Vice President Strategy of Royal Dutch Shell Group. In 2011 he became President-Director of Shell Netherlands. Dick was
            President & CEO of the Royal Schiphol Group from 2018 to 2022.
            He currently holds a number of board positions, is a Distinguished Fellow at the World Economic Forum, and does volunteer work. He is
            interim-CEO of the Mission Possible Partnership. Dick lives in The Hague, is married to Elisabeth and together they have three children.`,
        },
        {
            name: "Alexia Bertrand",
            description: `Alexia Bertrand is Federal Secretary of State for Budget and Consumer Protection. She obtained her law degree at the univeristy of Louvain-la-Neuve and then a Master of Laws at Harvard Law School. After a career as a lawyer in corporate law, she turned her expertise to politics. First as an advisor, then as  Chief of staff for general policy in the Cabinet of Deputy Prime Minister and Minister of Foreign Affairs Didier Reynders, a position she held until June 2019. Thereafter, and until her appointment as Secretary of State, she also served in the Brussels-Capital Parliament. She is also a local councillor in WoluweSaint Pierre. She has taught negotiation at various universities and private companies and was, prior to her appointment, a Board Member in several companies and non profits including a BEl-20 listed company.`,
        },
        {
            name: "Jason Bordoff",
            description: `Jason Bordoff is Founding Director of the Center on Global Energy Policy at Columbia University SIPA, where he is a Professor of Professional Practice, and is also Co-Founding Dean of the Columbia Climate School. He is also a Senior Advisor at Macro Advisory Partners. He previously served as Special Assistant to President Barack Obama and Senior Director for Energy and Climate Change on the Staff of the National Security Council, and, prior to that, held senior policy positions on the White House’s National Economic Council and Council on Environmental Quality. One of the world’s leading energy and climate policy experts, Bordoff’s research and policy interests lie at the intersection of economics, energy, environment, and national security. He is a columnist for Foreign Policy magazine, a frequent commentator on TV and radio, including NPR, Bloomberg, CNBC and BBC, has appeared on the Colbert Report, and has published in Foreign Affairs, the New York Times, Wall Street Journal, Financial Times, the Economist, and other leading outlets. He is a member of the Council on Foreign Relations and the Trilateral Commission, a Trustee of The Nature Conservancy in New York, a board member of Winrock International and Foreign Policy 4 America, and serves on the Leadership Council for Sustainable Energy for All at the United Nations. Earlier in his career, he was a scholar at the Brookings Institution, served in the Treasury Department during the Clinton Administration, and was a consultant with McKinsey & Company. Bordoff graduated with honors from Harvard Law School, where he served on the Harvard Law Review, and clerked on the U.S. Court of Appeals for the D.C. Circuit. He also holds an MLitt degree from Oxford University, where he studied as a Marshall Scholar, and a BA magna cum laude and Phi Beta Kappa from Brown University.`,
        },
        {
            name: "George E. Bogden",
            description: `Dr. George E. Bogden is a practicing attorney, researcher, and writer on international affairs. He completed his undergraduate degree at Yale, where he served as the university’s Fox International Fellow in Istanbul following graduation. Dr. Bogden obtained a doctoral degree in International Relations from the University of Oxford, as a Clarendon Scholar. His dissertation fieldwork in Kosovo was conducted during his tenure as a Fulbright Public Policy Fellow. Before training in law, Dr. Bogden helped establish a research hub at the Hudson Institute as its first Associate Director. Upon receiving a Dean’s Award Scholarship at NYU School of Law, he began its JD program, serving as a Senior Fellow at the Hungary Foundation in Budapest during his 3L year. He subsequently clerked at the U.S. Court of International Trade, followed by fellowships at Columbia Law School, Bard College, the German Marshall Fund, and the Kennan Institute. His research has been featured in the Washington Post and his commentary has appeared in The Atlantic, Newsweek, War on the Rocks, The Wall Street Journal, Lawfare, Diplomatic Courier, The Hill, The National Interest, and The Kyiv Independent, among other publications. Routledge and the Marine Corps University Press have published textbook chapters by Dr. Bogden. He has guest lectured at NYU’s Wagner School of Public Policy, the Budapest Business School, the Wilson Center, and the British Library. Dr. Bogden is a Rockefeller Fellow at the Trilateral Commission, a Term Member at the Council on Foreign Relations, and a Member of the Bretton Woods Committee.`,
        },
        {
            name: "Mathew Burrows",
            description: `Mathew Burrows serves as Program Lead and a Distinguished Fellow at the Stimson Center where he is establishing a Strategic Foresight Hub.  Burrows works with a broad range of partners, including the U.S. Government, other governments, businesses, NGOs and foundations on analyzing trends and possible scenarios and their broad implications.  He is co-author of the 2023 book on US and China sliding into war: Die Traumwandler: Wie China und die USA in einen neuen Weltkrieg schlittern.  In August 2013 he retired from a 28-year career in the State Department and CIA, the last 10 years were spent at the National Intelligence Council (NIC), the premier analytic unit in the US Intelligence Community.  In 2007, he was appointed Counselor which is the number three position in the NIC.  He was the principal drafter for the NIC publication Global Trends 2030: Alternative Worlds, which received widespread praise in the international media. Other positions included assignments as special assistant to the US UN Ambassador Richard Holbrooke (1999-2001) and deputy national security advisor to US Treasury Secretary Paul O'Neill (2001-02). He received a BA in American and European history in 1976 from Wesleyan University and a PhD in European history from the University of Cambridge in 1983.    
            Burrows’ contact info is mburrows@stimson.org.`,
        },
        {
            name: "Edoardo Campanella",
            description: `Edoardo Campanella is an economist and author. He works as senior global economist at UniCredit Bank and is a Research Fellow at the Harvard Kennedy School. He recently published with Marta Dassu’ Anglo Nostalgia: the Politics of Emotion in a Fractured West (Oxford University Press). He writes globally syndicated columns for Project Syndicate, and his writings have appeared, among the others, in Foreign Policy, Foreign Affairs, Survival and many other media outlets. Edoardo is also a former David Rockefeller Fellow of the Trilateral Commission, where he directed the Taskforce on Global Capitalism in Transition, culminating with the publication of A New Spirit of Capitalism. He previously worked for the economic research departments of the World Trade Organisation, the World Economic Forum and the Italian Senate. In 2016, he was a shortlisted author for the Bracken Bower Prize, awarded by the Financial Times and McKinsey to promising writers under the age of 35. He holds an MPA from the Harvard Kennedy School that he attended on a Fulbright scholarship. While at Harvard, he was awarded the Certificate for Teaching Excellence for his teaching activity. He is also affiliated with the Aspen Institute, the Centre for the Governance of Change of IE University in Madrid and the Council for Italy and the United States. `,
        },
        {
            name: "Christopher G. Cavoli",
            description: `General Christopher G. Cavoli is the Supreme Allied Commander Europe and the Commander of U.S. European Command, having assumed these duties in July 2022. General Cavoli was commissioned into the Infantry in 1987 and has served in a wide variety of positions throughout the U.S., Europe, and Asia. He’s commanded formations for more than 15 years at every level of the U.S. Army, in peacetime, on operations, and in combat. He’s also served as the Deputy Commander of Regional Command West in Herat, and as Deputy Commanding General for Operations of the 82nd Airborne Division. His staff experience includes service as the Director for Russia on the Joint Staff, Deputy Executive Assistant for the Chairman of the Joint Chiefs of Staff and Director of the Chief of Staff of the Army’s Coordination Group. General Cavoli held fellowships at the National Defense University, the George C. Marshall Center for European Security Studies and the Army Chief of Staff’s Strategic Studies Group. General Cavoli is a Foreign Area Officer with a concentration on Eurasia and holds degrees from Princeton University and Yale University. Before assuming his current duties, General Cavoli served as the Commanding General of U.S. Army Europe from Jan 2018 until Jun 2022, incorporating responsibilities for U.S. Army operations in Africa since Oct 2022. His awards include the Combat Infantryman’s Badge, the Ranger Tab and Master Parachutist’s wings.`,
        },
        {
            name: "Emma Chang",
            description: `Emma Chang, Government Affairs Strategy Analyst, Washington, D.C. works in the international security cooperation space for government clients. Previously in the workplace, she developed reports on geopolitical tensions, business sentiment, and trade policy for Deloitte US and Deloitte Global strategic leadership teams and board members. Emma graduated from Princeton University's School of Public and International Affairs with a minor in East Asian Studies and is multilingual. She also has prior
            work experience in a number of fields, including the non-profit, legal, and consulting industries.`,
        },
        {
            name: "Jean Charest",
            description: `As Deputy Prime Minister of Canada and Premier of Québec, and with a public service career spanning almost 30 years, Jean Charest is one of Canada’s best known political figures. Jean Charest was first elected to the House of Commons in 1984 and, at age 28, became Canada’s youngest cabinet minister as Minister of State for Youth. In 1991, he was named Minister of the Environment and Minister of Industry and Deputy Prime Minister of Canada in 1993.
            In 1994, Jean Charest was chosen Leader of the federal Progressive Conservative Party. He held that post until 1998 when he became Leader of the Quebec Liberal Party. Mr. Charest then broke a 50-year provincial record by winning three consecutive election campaigns in 2003, 2007 and 2008.
            Furthermore, the Charest government initiated an unprecedented labour mobility agreement between France and Québec and was best known for a major initiative for the sustainable development of Northern Québec called “Plan Nord”. Jean Charest is notably the initiator of the negotiation for the Canada-European Union Comprehensive Economic Trade Agreement (CETA). He is today a Partner at Canadian law firm Therrien Couture Joli-Coeur L.L.P., where he provides invaluable expertise to the firm’s clients with his in-depth knowledge and experience with public policy, corporate Canada and international matters.`,
        },
        {
            name: "Ivo H. Daalder",
            description: `Ivo H. Daalder is the Chief Executive Officer of the Chicago Council on Global Affairs and host of World Review with Ivo Daalder. He served as U.S. Ambassador to NATO under President Barack Obama and on the U.S. National Security Council under President Bill Clinton before joining the Council on Global Affairs as its president in 2013.`,
        },
        {
            name: "Alexander De Croo",
            description: `Alexander De Croo serves as the Prime Minister of Belgium since October 2020. De Croo has held various ministerial positions, including Deputy Prime Minister and Minister of Finance, in previous governments. Known for his commitment to digital innovation and economic reforms, De Croo has played a pivotal role in shaping Belgium's policies on technology, entrepreneurship, and fiscal matters. His leadership during the COVID-19 pandemic has been marked by a focus on public health and economic recovery. With a background in business and a reputation for pragmatism, De Croo has emerged as a key figure in Belgian politics, steering the country through a complex socio-economic landscape.`,
        },
        {
            name: "Laurence Deschamps-Laporte",
            description: `Laurence Deschamps-Laporte is the research director of the CÉRIUM (Montreal International Studies Centre) at l'Université de Montréal where is also an assistant professor of political science. Her research focuses on foreign policy, feminism, international relations as well as Middle East politics. She is currently a visiting fellow at Exeter College and the Department of Politics and International Relations, Oxford. Laurence Deschamps-Laporte advised three Canadian foreign ministers including as chief of staff. She was named in 2023 in the top 50 most influential in Canadian foreign policy by the Hill Times. She also offered strategic advice to global philanthropic and public sector organizations at McKinsey & Company. She holds a DPhil and MPhil from Oxford where she was a Rhodes scholar. She also holds a BA from UNC-Chapel Hill where she was a Morehead-Cain scholar.`,
        },
        {
            name: "Karen Donfried",
            description: `Dr. Karen Donfried is a Senior Fellow at the Belfer Center for Science and International Affairs at the Kennedy School of Government at Harvard University.  She stepped down as Assistant Secretary of State for European and Eurasian Affairs in Spring 2023. In that role, she served as Secretary of State Blinken’s top adviser on Europe and Eurasia, crafted U.S. policy on the region, negotiated with key foreign officials, testified before Congress, engaged with media, represented the State Department in interagency meetings and at public events, and led the largest regional bureau at State with 12,000 employees covering 50 countries during Russia's war against Ukraine.
 
            From 2014 to 2021, she served as president of the German Marshall Fund of the United States (GMF), a non-profit organization dedicated to strengthening transatlantic cooperation. GMF is headquartered in Washington, DC, and has seven offices in Europe.  Before leading GMF, Donfried was the special assistant to the president and senior director for European affairs on the National Security Council at the White House. In that capacity, she was the president’s principal adviser on Europe and led the interagency process on the development and implementation of the president’s European policies. Prior to the White House, Donfried served as the national intelligence officer (NIO) for Europe on the National Intelligence Council, the intelligence community’s center for strategic thinking. Donfried first joined GMF in 2001 after having served for ten years as a European specialist at the Congressional Research Service. From 2003-2005, she was responsible for the Europe portfolio on the U.S. Department of State’s Policy Planning Staff. She returned to GMF from 2005 to 2010, first as senior director of policy programs and then as executive vice president.
             
            Donfried is a member of the Council on Foreign Relations and the American Council on Germany. Donfried served as a non-resident Senior Fellow at the Center for European Studies at Harvard University from 2016 to 2021, and on the Strategic Committee of Sciences Po’s Paris School of International Affairs from 2017 to 2021. She was a board member of the American Friends of the Munich Security Conference and of the American Ditchley Foundation from 2020 to 2021. From 2017 to 2020, she served as a member of the Board of Trustees of Wesleyan University, her undergraduate alma mater. She was a member of U.S. Secretary of State John Kerry’s Foreign Affairs Policy Board from 2014 to 2017. From 2014 to 2016, Donfried served as vice chair of the World Economic Forum’s Global Agenda Council on the United States and then became a member of WEF’s Europe Council.  Donfried has a PhD and MALD from the Fletcher School of Law and Diplomacy at Tufts University and a Magister from the University of Munich, Germany. She has received awards from the governments of Belgium, Germany, Italy, and Lithuania.`,
        },
        {
            name: "Ine Eriksen Søreide",
            description: `Ine Eriksen Søreide is the Chair of the Standing Committee of Foreign Affairs and Defence in the Norwegian Parliament, where
            she represents the Conservative Party. She served as Norway's Minister of Foreign Affairs from 2017-2021, and Minister of
            Defence from 2013-2017. She has been elected to Parliament since 2001, Chairing the standing Committee of Foreign Affairs
            and Defence from 2009-2013. She holds a Law degree from the University of Tromsø, Norway.`,
        },
        {
            name: "Richard Falkenrath",
            description: `Richard is Senior Fellow for National Security at the Council on Foreign Relations.  Until last month, he was Head of Client Service and Head of Geopolitics at Bridgewater Associates, a premier asset management firm.  Richard joined Bridgewater in 2014 and is a Bridgewater Partner, and a member of the firm's Operating Committee.  He was Chief Administrative Officer from 2020 to 2022, where he managed the firm's legal, regulatory, security, human resources, and real estate departments; and led Bridgewater's handling of the COVID pandemic. Preceding his CAO role, Richard was Chief Security Officer from 2014 to 2019.  From 2000 to 2004, Richard was a member of the National Security Council and White House staff, ultimately as Deputy Assistant to the President and Deputy Homeland Security Advisor. He served as Deputy Commissioner for Counterterrorism of the New York City Police Department from 2006 to 2010. Prior to the White House, he was Assistant Professor at the John F Kennedy School of Government at Harvard University. He has also been the Stephen and Barbara Friedman Senior Fellow at the Brookings Institution and a Contributing Editor to Bloomberg TV.  Richard is also a member of the Aspen Strategy Group and a member of the Mission Committee of the Board of Governors of Lawrence Livermore National Laboratory.`,
        },
        {
            name: "Richard Fontaine",
            description: `Richard Fontaine is the Chief Executive Officer of CNAS. He served as President of CNAS from 2012–19 and as Senior Fellow from 2009–12. Prior to CNAS, he was foreign policy advisor to Senator John McCain and worked at the State Department, the National Security Council (NSC), and on the staff of the Senate Foreign Relations Committee.
            Fontaine served as foreign policy advisor to the McCain 2008 presidential campaign and subsequently as the minority deputy staff director on the Senate Armed Services Committee. Prior to that, he served as Associate Director for Near Eastern Affairs at the NSC from 2003–04. He also worked on Southeast Asian issues in the NSC’s Asian Affairs directorate.
            At the State Department, Fontaine worked for the deputy secretary and in the department’s South Asia bureau. Fontaine began his foreign policy career as a staff member on the Senate Foreign Relations Committee, focusing on the Middle East and South Asia. He also spent a year teaching English in Japan.
            Fontaine currently serves as executive director of the Trilateral Commission and on the Defense Policy Board. He has been an adjunct professor in the security studies program at Georgetown University’s School of Foreign Service.
            A native of New Orleans, Fontaine graduated summa cum laude with a BA in international relations from Tulane University. He also holds an MA in international affairs from the Johns Hopkins School of Advanced International Studies in Washington, and he attended Oxford University. He lives in Virginia with his wife and their four children.`,
        },
        {
            name: "Julia Friedlander",
            description: `Julia Friedlander joined Atlantik-Brücke, Germany's top platform for transatlantic exchange and professional network, as chief executive officer in June 2022. Before coming to Berlin, she spent two years at the Atlantic Council as the founding director of the Economic Statecraft Initiative, which handles the intersection between finance, economics and national security. She spent a decade in the U.S. government. She was senior policy advisor for Europe in the Office of Terrorism and Financial Intelligence at the U.S. Treasury from 2015 to 2017 and from 2019 to 2020, focusing on global sanctions policy and financial regulation, and as a macroeconomist in the Department of International Affairs. From April 2017 to July 2019, she was detailed to the White House as the director for the European Union, Southern Europe, and Economic Affairs at the National Security Council, where she coordinated U.S. executive branch policy on transatlantic relations and the European Union and staffing the national security advisor. Before joining the Treasury in 2015, she held multiple analytical positions within the Central Intelligence Agency, focusing on global economic stability and energy security.
 
            Ms. Friedlander holds a BA in European history from Princeton University and an MA in international economics and international relations from the Johns Hopkins School of Advanced International Studies. Before graduate school, she spent two years in Berlin with a grant from the German Academic Exchange Service (DAAD) at the German Council on Foreign Relations and The German Marshall Fund of the United States.`,
        },
        {
            name: "Dijana Galijasevic",
            description: `Dijana Galijasevic is the co-founder and CEO of Impact Hero, a Berlin-based company at the forefront of integrating impactful environmental and social initiatives into business operations. They specialize in helping businesses create and communicate positive impact by integrating initiatives like tree planting, plastic collection, and reef restoration into their operations, engaging key stakeholders. With Impact Hero, Dijana drives the innovation of impact automation solutions empowering businesses to actively combat climate change and foster global regeneration.

            Complementing her dedication to sustainable business practices, Dijana is pursuing and completing a PhD in Ethics and Responsible Leadership in Business at HHL Leipzig Graduate School of Management in Germany. Her research is focused on the crucial balance between leadership and responsibility—qualities she considers essential for addressing complex global challenges.
            
            Born in Sarajevo, Bosnia and Herzegovina, Dijana and her family fled to Slovenia due to the horrors of war. These early experiences inspired her lifelong commitment to fostering peace and greater social awareness through her work. Dijana graduated in International Relations from the University of Ljubljana and furthered her education with a master’s in Marketing and Sales from ESADE and SDA Bocconi.
            
            Her professional journey includes roles within the Ministry of Transport in Slovenia and leadership positions across various social enterprises. Through Impact Hero and its notable initiative, Grow My Tree, which has successfully planted over 2.5 million trees, Dijana underscores her practical approach to environmental advocacy and social awareness. She firmly believes that businesses can be potent catalysts for positive transformation, shaping a sustainable world for future generations.
            
            Dijana is also a speaker and author, having shared her insights on platforms such as futur/io and co-authoring the book 'Moonshots for Europe'. Her career is a testament to her mission to infuse business practices with kindness and responsibility, aiming to create a fair future for our planet and humanity.
            
            In addition to her business and academic endeavors, Dijana is a David Rockefeller Fellow at the Trilateral Commission.
            `,
        },
        {
            name: "Alicia García Herrero",
            description: `
            Alicia García Herrero is the Chief Economist for Asia Pacific at Natixis CIB. She is also an independent Board Member of AGEAS insurance group. Alicia also serves as Senior Fellow at the European think-tank BRUEGEL and as a non-resident Senior Follow at the East Asian Institute (EAI) of the National University Singapore (NUS). Alicia is also Adjunct Professor at the Hong Kong University of Science and Technology (HKUST). Finally, Alicia is and an advisor to the Spanish government on economic affairs, a Member of the Board of the Center for Asia-Pacific Resilience and Innovation (CAPRI), a member of the Advisory Board of the Berlin-based Mercator Institute for China Studies (MERICS), an advisor to the Hong Kong Monetary Authority’s research arm (HKIMR) and a Member of the Council of the Focused Ultrasound Foundation (FUF).   
            Alicia is very active in international media (such as BBC, Bloomberg, CNBC and CNN) as well as social media (LinkedIn and Twitter). As a recognition of her thought leadership, Alicia was included in the TOP Voices in Economy and Finance by LinkedIn in 2017 and #6 Top Social Media leader by Refinitiv in 2020.`,
        },
        {
            name: "Philip Gordon",
            description: `Philip Gordon is National Security Advisor to Vice President Kamala Harris. He has previously served in a number of senior
            foreign policy positions in the U.S. government, including as White House Coordinator for the Middle East, North Africa, and the
            Gulf Region; Assistant Secretary of State for European and Eurasian Affairs; and NSC Director for European Affairs.
            Dr. Gordon has also held research and teaching positions at the Council on Foreign Relations, the Brookings Institution, the
            International Institute for Strategic Studies, INSEAD, the German Council on Foreign Relations, SAIS, and RAND. Gordon has
            a Ph.D and MA in international relations from Johns Hopkins University's School of Advanced International Studies and a BA
            from Ohio University.
            He is the author of a number of books and monographs on international relations and foreign policy including, most recently,
            Losing the Long Game: The False Promise of Regime Change in the Middle East (2020) and has written frequently for The New
            York Times, The Washington Post, Financial Times, Foreign Affairs, Foreign Policy, The Atlantic, Politico, and other publications.`,
        },
        {
            name: "Michael B. Greenwald",
            description: `Michael B. Greenwald is the Global Head of Financial Innovation and Digital Assets at Amazon Web Services (AWS). He is also heads up Global Executive Relations for the company pioneering thought-leadership on artificial intelligence. In 2023, Michael was appointed by the U.S. Commodity Futures Trading Commission (CFTC) to represent Amazon on the CFTC’s Technology Advisory Committee. 
            Prior to Amazon, he was a managing director of Digital Asset Education and Chief Geopolitical Risk Officer for global asset management firm AlTi Global. He previously worked for the U.S. Treasury Department for almost a decade. He served in senior policy and intelligence roles in Europe and Africa for two presidential administrations and three Treasury Secretaries culminating with becoming the first U.S. Treasury attaché to Qatar and Kuwait, acting as the principal financial diplomat to the banking sector in those nations. 
            He is an Adjunct Associate Professor of International and Public Affairs at Columbia SIPA. He is a graduate of Harvard Business School and has a Juris Doctor graduate from Boston University School of Law, a Master’s graduate from Boston University’s Frederick S. Pardee School of Global Studies, and a B.A. from George Washington University. `,
        },
        {
            name: "Jane Harman",
            description: `Jane Harman served nine terms in Congress as the U.S. representative for California's 36th congressional district and was ranking member of the Intelligence Committee after 9/11. She left the House in 2011 to become the first woman president and CEO of the Wilson Center, transitioning to President Emerita in 2021. 

            Currently she chairs the Commission on the National Defense Strategy and co-chairs the board of Freedom House. She is also a Trustee of the Aspen Institute, the Trilateral Commission, a Presidential Scholar at USC and serves on the advisory boards of the Department of Homeland Security, and NASA. Harman’s book, “Insanity Defense: Why Our Failure to Confront Hard National Security Problems Makes Us Less Safe,” was published by St. Martin’s Press in 2021.`,
        },
        {
            name: "Masahisa Ikeda",
            description: `Masahisa Ikeda is the Global Co-Head of Sector Strategy, Co-Managing Partner of Japan & South Korea and Head of Tokyo,Allen Overy Shearman Sterling LLP. Masahisa has extensive experience representing global companies and government agencies, including the Ministry of Foreign Affairs of Japan, on a wide variety of matters, including cross-border acquisitions, financings
            and disputes. He also represents numerous corporations on various government investigations and regulatory and compliance matters, including investigations relating to securities law, antitrust law and FCPA, before the U.S. Department of Justice, the U.S. Securities and Exchange Commission and the European Commission.Masahisa speaks frequently on international business issues and legal issues. Masahisa is on the board of numerous non-profit organizations in the U.S. and Japan, including serving as the Asia Pacific Director of the Trilateral Commission, a Board Member of the Tokyo Foundation for Policy Research, an Auditor of the International House of Japan, Research Collaborator of the Japan
            Fair Trade Commission, an Executive Committee Member of the Harvard Law School Association, and a Founder and Council of UWC ISAK Japan. Masahisa has been named a leading practitioner for capital markets, M&A and asset management by Chambers Global, Chambers Asia-Pacific, The Legal 500 Asia Pacific, IFLR 1000 and the Nihon Keizai Shimbun.`,
        },
        {
            name: "Daisuke Kawai",
            description: `Daisuke Kawai is the Deputy Director and a Project Assistant Professor at the Economic Security Research Program, Research Center for Advanced Science and Technology (RCAST), The University of Tokyo. His areas of expertise include Indo-Pacific security and economic security, with a special interest in critical and emerging technologies (CETs). He is also a senior researcher at the Keio SFC institute, Keio University, and the Asia Fellow at the Center for International Private Enterprise (CIPE), the U.S. Chamber of Commerce.
            His work has contributed to the development of Japan’s Economic Security Promotion Act (ESPA). Currently, he leads the Critical and Emerging Technology cooperation within the Quad (Japan, the United States, Australia, and India).
            Previously, he was a Research Fellow for Indo-Pacific Affairs at the Japan Institute of International Affairs (JIIA), and a council member of the think tank function at the Japan Coast Guard, and worked at the Royal United Services Institute (RUSI). Additionally, he served as the secretariat of the Council for Security Cooperation in the Asia Pacific (CSCAP) Japan. `,
        },
        {
            name: "Yasmine Kherbache",
            description: `Judge of the Constitutional Court of Belgium
            Yasmine Kherbache (° 03/04/1972) is judge of the Constitutional Court of Belgium since December 2019 and researcher at the University of Antwerp (Research Group Government & Law). Previously she worked as a Member of Parliament on the regional and national level, as chief of staff of Belgian Prime Minister Elio Di Rupo, as deputy chief of  staff of social-democratic minister of Education and Employment Frank Vandenbroucke and on the local level as party leader in the city council of Antwerp. Her political work focused on social policy, foreign affairs,  state reform, constitutional law and human rights.
            She started her professional career as a lawyer at the international law firm De Bandt, Van Hecke, Lagae (now Linklaters) and  founded her own office specialized in intellectual property and labour law
            Kherbache subsequently studied law at KU Leuven and at the Université libre de Bruxelles. She studied for one year at the Universitat de Barcelona (Erasmus) where she specialised in constitutional law and the institutional reforms in Catalonia.  
            
            Personal details:
            Belgo-Algerian, married and mother of two. 
            Member of the Trilateral Commission
            Commander in the Order of Leopold`,
        },
        {
            name: "Taro Kono",
            description: `Taro Kono was appointed Minister for Digital Transformation, Minister for Digital Reform, Minister for Consumer Affairs and
            Food Safety, and Minister in charge of Civil Service Reform in 2022, and was reappointed Minister for Digital Transformation
            and Minister for Administrative Affairs in the 2nd Kishida Cabinet in 2023. He has also served as Minister for Foreign Affairs
            (2017–19) and Minister of Defense (2019–20). Mr. Kono was first elected to the House of Representatives in 1996 and has
            been re-elected nine times. He graduated from the School of Foreign Service, Georgetown University in 1985
             `,
        },
        {
            name: "Mia Kovačić",
            description: `Mia Kovačić, born in Belgrade, is Director Communications at CWP Global, a world leader in ultra-large-scale green hydrogen projects. She began her career in the luxury industry in China before dedicating herself to promoting sustainable development. Following five years managing the marketing team at Resalta, the leading energy services provider in Southeast Europe, and a period at La Farge Serbia as Head of Marketing, Communications and Sustainability, she joined CWP Global's hydrogen business as Communications Director, covering the group’s ambitious project portfolio of 200+ GW of renewable energy capacity across four continents. Mia is a passionate advocate for finding real and practical solutions for lowering greenhouse gas emissions, and dedicated to ensuring businesses and communities are well prepared for the net zero future. Educated in France and China, she holds degrees in political science and communications from Sciences Po Paris and Fudan University and speaks five languages.`,
        },
        {
            name: "Nicholas Kristof",
            description: `Nicholas Kristof is a two-time Pulitzer Prize-winning columnist for The New York Times. He grew up on a farm in Oregon, graduated from Harvard, earned a First in law at Oxford University as a Rhodes Scholar, and then studied Arabic in Cairo. He joined The Times in 1984 and served as bureau chief in Hong Kong, Beijing and Tokyo, then served as editor of The Sunday New York Times. After 9/11, he became an op-ed columnist. Kristof won his first Pulitzer for reporting on China and the other for columns on Darfur, along with an Emmy for coverage of Covid. Kristof, who has lived on four continents and traveled to more than 160 countries, has more than 2.5 million followers on social media. He has written six books, most recently a memoir called “Chasing Hope,” lives on the family farm in Yamhill, Oregon, where he makes wine and hard cider.`,
        },
        {
            name: "Kurt Lauk ",
            description: `Prof. Dr. Lauk is founder and president of Globe CP GmbH, a personally owned family office since 2000. Today he serves as advisory board at several start-ups companies in the US and Israel. 
            Dr. Lauk currently serves as independent director on supervisory boards Fortemedia (Cupertino) and three advisory boards. Germany: ADS-Tech Energy, (Nürtingen) listed ADSE, Guardknox Cyber Technologies (Israel) and Visby (Santa Clara, US). 
            He served as a Trustee of IISS (International Institute of Strategic Studies in London), 2000-2023. 
            From 1995-2000 he was a Member of the Board of Management of Daimler and Head of commercial vehicle division of Daimler in 1996. 
            From 1992-1995 Chief Financial Officer at VEBA (today E.ON), 1989-1992 Deputy Chairman and Chief Financial Officer of Audi AG. 1984–1987 CEO at Zinser Textil Machinery GmbH, 1978-1984 Vice President/Consultant/Partner at Boston Consulting Group.
            
             Dr. Kurt J. Lauk has been national chairman of the CDU´s economic council (Wirtschaftsrat der CDU e.V.) from 2000-2015 (since June 2015 Honorary Chairman). Member of the executive committee (Bundesvorstand) of the Christian Democratic Party, 2000-2015. Between 2004-2009 Dr. Lauk was Member of the European Parliament for the Economic and Monetary Affairs Committee and Deputy Member of the Foreign and Security Affairs Committee.
            `,
        },
        {
            name: "Koen Lenaerts",
            description: `Born in 1954 in Mortsel, Belgium, Koen Lenaerts obtained his law degree in 1977 at the Katholieke Universiteit Leuven (Catholic
                University of Leuven, Belgium) before continuing his studies at Harvard University, where he obtained a Master of Laws in 1978
                and a Master in Public Administration in 1979. He then returned to the Katholieke Universiteit Leuven, where he became a
                Doctor of Law in 1982.
                His professional career began in this same university in 1979, when he started as a lecturer, and later becoming a professor of
                European law in 1983. During his university career, he also taught at the College of Europe in Bruges (Belgium) from 1984 to
                1989, and at Harvard Law School as a visiting professor in 1989.
                Mr Lenaerts' career at the Court of Justice began when he became legal secretary to Judge René Joliet, a post he occupied from
                1984 to 1985, before practising law at the Brussels Bar from 1986 to 1989.
                He was appointed judge at the Court of First Instance of the European Communities on 25th September 1989, the first day of
                this newly created court. He served on this Court for more than 14 years before being appointed judge at the Court of Justice on
                7th October 2003. He was elected by his peers as President of Chamber for two successive mandates from 9th October 2006
                to 8th October 2012 and then as Vice-President of the Court of Justice on 9th October 2012.
                He was elected President of the Court of Justice on 8th October 2015, a post he occupies to this day.`,
        },
        {
            name: "Thomas Leysen",
            description: `Thomas Leysen, born 1960, is a Belgian entrepreneur. 
            He is Chairman of Umicore, a materials technology group, and of Mediahuis, a European media group and publisher of leading newsbrands in Belgium, The Netherlands, Luxembourg and Ireland 
            Since 2021 he is Chairman of dsm-firmenich, a Swiss-Dutch listed Health, Nutrition and Beauty 
            Group. 
            Thomas Leysen has an MA in Law from the University of Leuven.`,
        },
        {
            name: "Monica Maggioni",
            description: `Born in Milan, Italy, Certified journalist, Anchor Woman and Director of RAI.TV. She has worked as a foreign correspondent, writer, documentary maker, newscast director, and President-General of Rai, she was the first female Director of TG1.  She is in the Board of the ISPI (Institute for International Political Studies) and of the European Council on Foreign Relations, and is a member of the Aspen Institute and of the Trilateral Commission. In recent years she has been reporting on the crises from the main critical areas of the planet: Iraq, Iran, Syria, Afghanistan, Burma, and African countries. She has interviewed many heads of state, bloggers, pacifists and terrorists. In the U.S., from 2008 she reported on Obama's presidential campaign and on the financial crisis. In 2010 and 2011 she produced two documentaries screened at the Venice Film Festival, namely Ward 54 – winner of the Mitrani Prize in Biarritz – and Out of Tehran. 
            As an author she wrote "Dentro la guerra" (Inside the war) published in 2005 by Longanesi, "La fine della verità" (The end of truth) published in 2006 by Longanesi, and "Terrore mediatico" (Media terror) published in 2015 by Laterza; she is also the author of several publications including "Twitter and jihad" published in 2015 by ISPI.`,
        },
        {
            name: "Sigal Mandelker",
            description: `Sigal Mandelker joined Ribbit Capital in April 2020. Ribbit is an investment firm focused on
            financial services and technology. Prior to Ribbit, Sigal served as Acting Deputy Secretary of the U.S. Treasury Department and as Under Secretary for Terrorism and Financial Intelligence. As Under Secretary, Sigal supervised four main components of Treasury (OFAC, FinCEN, the Office of Intelligence and Analysis, and the Office of Terrorist Financing and Financial Crimes). Before serving at Treasury, Sigal was a partner at Proskauer in New York. Sigal also previously served in a number of senior positions in the U.S. government, including as Deputy Assistant Attorney General in the Criminal Division of the Justice Department, an AUSA in the U.S.
            Attorney’s Office for the Southern District of New York, Counselor to Secretary of Homeland
            Security Michael Chertoff, Counsel to the Deputy Attorney General, and as a law clerk on the
            U.S. Supreme Court. Sigal is also an Advisor to Chainalysis, has sat on the Boards of the
            Crypto Council for Innovation and the Financial Technology Association, served as Co-Chair of the Center for a New American Security’s (CNAS) task force on FinTech, Crypto, and National Security, is a Member of the US Holocaust Memorial Museum Council, serves on the Museum's Executive Committee, and is Chair of the Museum's Collections & Acquisitions Committee. Sigal is also a Member of the Council on Foreign Relations.
            `,
        },
        {
            name: "Daniel Michaels",
            description: `Daniel Michaels is Brussels Bureau Chief of The Wall Street Journal, overseeing coverage of the European Union, European
            affairs, NATO and European security.
            Prior to this position in April 2017, Mr. Michaels was the Journal's German Business Editor for three years, based in Frankfurt,
            overseeing corporate reporting on Europe's biggest economy and coverage of the European Central Bank.
            For 15 years prior to that, Mr. Michaels was the Journal's Aerospace & Aviation Editor for Europe, based in Paris and Brussels.
            In that position he covered airlines, aviation and aerospace industries in Europe, Africa and the Middle East, and coordinated
            with other Journal reporters worldwide.
            From 1993 to 1999, Mr. Michaels covered Central & Eastern Europe for the Journal, based in Warsaw, focusing on business,
            politics and society. He initially went to Poland in 1991 to work as a management consultant in privatization; he also worked
            briefly on privatization in Russia.
            Before moving to Poland, Mr. Michaels was a management consultant with Booz, Allen & Hamilton in New York City, working
            across a range of industries in the U.S. and Canada.
            Mr. Michaels is a native of New York and graduated from Princeton University, where he studied China and East Asia. In
            addition to living in Europe since 1991, he earlier lived and worked in China and Taiwan. He speaks French, Polish, Mandarin
            Chinese and basic German. `,
        },
        {
            name: "René Moerland",
            description: `René Moerland is Publisher and Editor-in-Chief of Euractiv, a pan-European media company that has been part of the international Mediahuis Group since 2023.
            As Editor-in-Chief, he is leading the Euractiv newsrooms in Brussels, Berlin and Paris and the Euractiv network of contributors from the EU member states focusing on high quality coverage of the dynamics of EU-politics in the EU-capitals and specialised coverage of European policymaking. Euractiv focuses on European Union policy in eight domains: agri-food, economy, energy and environment, global Europe, health, politics, technology and transport. Euractiv news content is provided in 12 languages.
            From 2019 to 2024, René Moerland was Editor-in-Chief of NRC, an eminent Dutch media that is also part of the Mediahuis Group. In this role, he focused on high quality journalism on politics, economy, societal affairs, international affairs and culture for a growing well-informed highly educated general audience. NRC also established itself as a leading audio-on-demand media in the Netherlands in recent years.
            From 2010 to 2019, René Moerland directed the international and political coverage of NRC Handelsblad and managed it’s morning and weekend editions. He also worked as a political editor at NRC Handelsblad and was the newspapers correspondent in Paris and Brussels. `,
        },
        {
            name: "Mario Monti",
            description: `Mario Monti is Senator for life of the Italian Republic, President of Istituto Javotte Bocconi and honorary President of the Institute for European Policy-Making at Bocconi University, having been President of the same university from 1994 to 2022.
            He was Prime Minister of Italy (2011-2013), heading a government of national unity, which overcame Italy’s sovereign debt crisis and launched structural reforms.
            Monti had previously served for ten years as Member of the European Commission, in the capacity of Commissioner for the Single Market (1995-1999) and then for Competition (1999-2004).
            He has been member of several advisory commissions in Italy (e.g. Monti Commission on banking and financial reform, Italian Treasury, 1982), the UK (Roll Committee, 2003), and France (Attali Commission on economic growth in France, 2007). In 2020-2021 he chaired the Pan-European Commission on Health and Sustainable Development, convened by WHO/Europe, and he is currently member of the Lancet Commission on 21st-Century Global Health Threats.
            At the EU level, he has been the author of A New Strategy for the Single Market (2010), and the Chairman of a High-level Group created by the european institutions that released the report Future Financing of the EU (2017).
            Monti has been the founding chairman of IGIER, established at Bocconi with CEPR and NBER (1990), and of Bruegel, the European think-tank based in Brussels (2005).
            `,
        },
        {
            name: "Ana Janaina (Jana) Nelson",
            description: `Ana Janaina (Jana) Nelson is a Senior Policy Advisor for Ambassador to the U.S. Mission at the United Nations Linda Thomas Greenfield. Jana has over fifteen years of experience working in and with Latin America and the Caribbean in both the private sector and government. In the private sector, Jana worked at Amazon Web Services, helping address the challenge of digital transformation of governments and the digital divide in Latin America; and Bacardi, working on responsible drinking initiatives in the Americas.
            In the U.S. government, Jana served twice in the Department of State; in the U.S. Senate; and at the Pentagon in the Office of the Secretary of Defense. In public service, she helped advance the U.S-Brazil bilateral relationship; coordinated the draft of the original Women, Peace, and Security bill in 2012; supported efforts to combat fentanyl trafficking; and helped resettle six former Guantanamo Bay detainees in Uruguay.
            Jana received her M.A. from Georgetown University’s School of Foreign Service and her B.A. in International Relations from the University of Brasília. She is a native speaker of English and Portuguese and is fluent in Spanish, with working knowledge of French. In Latin America, Jana has lived and worked in Brazil, Mexico, Colombia, and Argentina. She has offered frequent media commentary and analysis of Latin America policy issues, including in the New York Times, Vox, El Universal, and Folha de S. Paulo.`,
        },
        {
            name: "Tak Niinami",
            description: `Tak Niinami is the Chief Executive Officer of Suntory Holdings, one of the world's leading consumer products companies with
            operations throughout the Americas, Europe, Asia and Oceania.
            Tak spent the first 20 years of his career at Mitsubishi Corporation, where he held a number of management positions and
            founded Sodex Corporation (now LEOC Co.), a hospital food service joint venture between Mitsubishi and Sodexo of France,
            ultimately taking the company public in 1999. Prior to joining Suntory in 2014, Tak was CEO of Lawson, Inc., which he grew
            into one of Asia's major convenience store operators.
            Outside of Suntory, Tak has served on the Council on Economic and Fiscal Policy as senior economic advisor to the Prime
            Minister since 2014, the longest serving business leader in Japan. He has served for former Prime Ministers Abe and Suga, and
            the incumbent Prime Minister Kishida. He also represents Japan corporate executives as Chairman of the Japan Association of
            Corporate Executives.
            Tak is also Asia Pacific Chairman of the Trilateral Commission, Chair of the Asia Business Council, Vice Chair of the United
            States-Japan Foundation, and a member of the World Economic Forum's International Business Council, the Global Board of
            Advisors of the Council on Foreign Relations, and The Business Council in the US.
            Tak received his MBA from Harvard Business School.`,
        },
        {
            name: "Annahita Nikpour",
            description: `Annahita Nikpour is the deputy European Correspondent at the Ministry of Foreign Affairs of Denmark. She is responsible for the coordinating the Danish participation in the Foreign Affairs Council meetings as well as the Danish engagement in various European foreign and security policies. Her main areas of work include the war in Ukraine, the crises in the Middle East and the Nordic-Baltic security cooperation. Further, she is part of the Emergency Response Unit at Danish Red Cross, which sets up immediate humanitarian support to disaster-affected countries. Prior to joining the Ministry of Foreign Affairs, she served as advisor to the secretary general at Oxfam Denmark, focusing on how to reduce global economic, social and political inequality and strengthening ties with partners in the Global South. She has worked for various international institutions including UNICEF and Centre for European Politics (University of Copenhagen). She holds a master degree in Political Science from University of Copenhagen and has specialised in international relations of the Middle East at Exeter University. She joined the Trilateral Commission as a Rockefeller Fellow in the spring 2020. She is in a relationship and has a son and a daughter is on the way.`,
        },
        {
            name: "Omid Nouripour",
            description : `Omid Nouripour was born on June 18th, 1975 in
            Tehran, Iran. At the age of thirteen, he moved to
            Frankfurt/Main, Germany with his parents and his
            sister. Mr. Nouripour was granted German citizenship
            in 2002 and holds both an Iranian and a German
            passport.
            After graduating from high school in Frankfurt/Main,
            Omid Nouripour studied German Philology, Political
            Science and Law, as well as Philosophy and
            Macroeconomics at Johannes Gutenberg-University,
            Mainz.
            Before becoming a member of the German
            Parliament, he worked as an assistant to a member of
            the European Parliament and as a journalist for a
            leading German newspaper.
            Since 1996, Omid Nouripour has been an active
            member of the German Green Party (Alliance 90/The
            Greens). He first worked on an initiative for
            strengthening the rights of immigrants in Germany.
            From 1999 until 2003 he was the chairman of the
            Green Party’s Youth Organisation in Hessen, his
            German home state. In December 2002 Omid
            Nouripour was elected to the board of the federal
            Green Party and he remained on as a board member
            until December 2006. From 2002 until 2009 he was
            also the spokesperson of the Federal Working
            Committee on Migrants and Refugees of the Green
            Party.
            Omid Nouripour became a member of parliament in
            September 2006, taking over the seat of Joschka
            Fischer, the former German foreign minister. Mr.
            Nouripour first worked on European issues and Home
            affairs and was a member of the Budget Committee,
            the Committee of Defence, and the Committee for
            Human Rights and Humanitarian Affairs. He was
            Foreign Policy spokesperson of Germany&#39;s Green
            Party in the Bundestag for several years.
            He was re-elected as a Member of Parliament in the
            September 2021 federal election and won the seat in
            his constituency. His party now forms the current
            German government in a coalition with the Social
            Democrats (SPD) and the Liberals (FDP).
            He continues to be a member of the Foreign Affairs
            Committee, the Committee of Defence as well as the
            Finance Committee.
            After being elected in January 2022, Omid Nouripour
            took office as leader of his party Alliance/The Greens
            alongside Ricarda Lang.`,
        },
        {
            name: "Meghan L. O’Sullivan ",
            description: `Meghan L. O’Sullivan is the Jeane Kirkpatrick Professor of the Practice of International Affairs, Director of the Geopolitics of Energy Project, and Director of the Belfer Center for Science and International Affairs at Harvard University’s Kennedy School.

            Dr. O’Sullivan’s scholarship continues to be at the nexus of traditional disciplines, examining topics at the intersection of geopolitics, science, markets, and policy. Her work on large changes in the energy system has shaped how policymakers and academics alike view these issues. Her publications in this area include her award-winning 2017 book Windfall: How the New Energy Abundance Upends Global Politics and Strengthens America’s Power.
            
            O’Sullivan has served in multiple senior policymaking roles and has advised national security officials in both Republican and Democratic administrations. She is the recipient of numerous awards for her public service, including the U.S. Defense Department’s highest honor for civilians (the Distinguished Public Service Medal) and the State Department’s Superior Honor Award (which she has received three times).
            
            O’Sullivan is a member of U.S. Secretary of State Antony Blinken’s Foreign Affairs Policy Board. Between 2004 and 2007, she was special assistant to President George W. Bush and was Deputy National Security Advisor for Iraq and Afghanistan during the last two years of her tenure. In 2013, O’Sullivan was the Vice Chair of the All Party Talks in Northern Ireland. 
            
            O’Sullivan also has extensive experience advising the private sector on geopolitical risk and in board leadership and advisory roles in non-profit organizations. She is a Senior Director at the strategic consulting firm Macro Advisory Partners, and is the Chair of the North American Group of the Trilateral Commission.
            
            O’Sullivan earned a bachelor’s degree from Georgetown University and a masters of science in economics and doctorate in politics from Oxford University. She was a Henry Crown Fellow from 2015 to 2017 and a Henry Luce Fellow in Indonesia from 1991 to 1992.`,
        },
        {
            name: "David H. Petraeus",
            description: `General David Petraeus, US Army (Ret.), is a Partner with the global investment firm KKR and the Chairman of the KKR Global Institute, which he established in June 2013.  He is also a personal venture capitalist and serves on the boards of KKR companies OneStream and Optiv.  Before joining KKR, he served in government for 38-1/2 years, culminating his 37-year military career with six consecutive commands as a general officer, five of which were in combat – including the Surge in Iraq, US Central Command, and US/Coalition Forces in Afghanistan – and then serving as Director of the CIA, following Senate confirmation by a vote of 94-0.  He graduated with distinction from the US Military Academy in 1974 and later earned a Ph.D. at Princeton University.  He has held academic appointments at USMA, the City University of New York’s Macaulay Honors College, Harvard’s Belfer Center, and the University of Southern California.  He is currently a Senior Fellow and Lecturer at Yale’s Jackson Institute for Global Affairs, Co-Chairman of the Woodrow Wilson Center’s Global Advisory Council, Senior Vice President of the Royal United Services Institute, and a member of the Trilateral Commission, Council on Foreign Relations, and Aspen Strategy Group.  His many awards include four Defense Distinguished Service Medals, the Secretary of State’s Distinguished Service Award, the Bronze Star Medal for Valor, two NATO Meritorious Service Medals, the Combat Action Badge, the Ranger tab, and master parachutist wings.  He has also been decorated by 13 foreign countries and is believed to be the only person who, while in uniform, threw out the first pitch of a World Series game and did the coin toss for a Super Bowl.  He was also runner-up for Time Person of the Year in 2007, when Russia’s President Vladimir Putin garnered that honor.`,
        },
        {
            name: "Francesco Rocchetti",
            description: `Francesco Rocchetti is the Secretary General of ISPI (Italian Institute for International Political Studies), an independent, non-partisan, non-profit think tank, and the Curator of the Rome MED Dialogues, a leading conference on the Mediterranean and the Middle East.
            Previously, he conducted research on geoeconomics and international trade and led the ISPI communication team. 
            Before joining ISPI, he held positions at the European Parliament and the OECD. 
            He studied at the University of Milan and Sciences Po Paris.
            He is the co-host of “Globally“, the most popular Italian podcast on international affairs.`,
        },
        {
            name: "David E. Sanger",
            description: `David E. Sanger is a White House and national security correspondent for The New York Times, and one of the news organization’s senior writers. In a 42-year reporting career at The Times, he has played central roles on three teams that have won Pulitzer Prizes, most recently in 2017 for international reporting. 

            In April, 2024 he published “New Cold Wars: China’s Rise, Russia’s Invasion and the Struggle to Defend the West," a New York Times best-seller that explores critical misjudgements made by the West over the past 30 years about the future course of the world's largest superpowers, and takes readers deep inside the Biden White House as it has attempted to manage the confrontation with Russia over Ukraine and the technology competition with China. 
             
            His previous book was “The Perfect Weapon: War, Sabotage and Fear in the Cyber Age’’ (2018) and an HBO documentary by the same title (2020), which examines the emergence of cyberconflict and its role in changing the nature of global power.
            He is also the author of two previous Times best sellers on foreign policy and national security: “The Inheritance: The World Obama Confronts and the Challenges to American Power,” published in 2009, and “Confront and Conceal: Obama’s Secret Wars and Surprising Use of American Power,” published in 2012.
              
            For The Times, Mr. Sanger has served as Tokyo bureau chief, Washington economic correspondent, White House correspondent during the Clinton and Bush administrations, and chief Washington correspondent. In the late 1980's and early 1990's Mr. Sanger spent six years in Tokyo, writing about the emergence of Japan as a major American competitor, and then the country’s humbling recession. He wrote many of the first articles about North Korea’s emerging nuclear weapons program.
            Returning to Washington, Mr. Sanger turned to a wide range of diplomatic and national security issues, from nuclear proliferation to the rise of cyberconflict among nations. He revealed the story of Olympic Games, the code name for the most sophisticated cyberattack in history, the American-Israeli effort to sabotage Iran’s nuclear program with the Stuxnet worm. His journalistic pursuit of the origins of Stuxnet became the subject of the documentary “Zero Days,” which made the short list of Academy Award documentaries in 2016.
            
            A 1982 graduate of Harvard College, Mr. Sanger was the first senior fellow in The Press and National Security at the Belfer Center for Science and International Affairs at Harvard. With Graham T. Allison Jr., he co-teaches “Central Challenges in American National Security, Strategy and the Press” at the Kennedy School of Government. He is also a national security contributor to CNN. `,
        },
        {
            name: "Bob Sternfels",
            description: `Bob is the global managing partner of McKinsey & Company and chairs the firm's board of directors. His focus is ensuring
            McKinsey lives up to its dual mission of helping our clients realize lasting and substantial improvements in their performance;
            while also attracting, developing and retaining exceptional people in an inclusive culture.
            Bob is a founding member and chair of the African Leadership Group Advisory Board and a Co-Chair of the First Chance Campaign
            at Concordance. He sits on the boards of the US China Business Council, New York City Jobs Council and FCLTGlobal.
            He is an active member of the Business Roundtable, Asia Business Council, The International Advisory Council for The Singapore
            Economic Development Board, Tsinghua SEM Advisory Board, Trilateral Commission and the Council on Foreign Relations.
            Bob is a graduate of Stanford University and the University of Oxford. He serves in a personal capacity on the board of Quest-
            Bridge, the advisory board of USA Water Polo, and as a trustee of the Rhodes Trust. He is also a private pilot. Bob is married to
            Ashley, and they have three children.`,
        },
        {
            name: "Siddharth Tiwari",
            description: `Siddharth Tiwari is currently on the Advisory Council of the Bretton Woods Committee, Washington, DC, a Fellow at the Chatham House, London, and Vice-President of the International Finance Forum, Beijing. At various phases during his career, Mr. Tiwari has served as Senior Advisor to India’s G20 Presidency, headed the Bank for International Settlements’ Asia and Pacific Office in Hong Kong, been the Executive Secretary of the G20 Eminent Persons Group in Singapore, as well as had a career in the IMF that included assignments as Director of Strategy Policy and Review, Secretary of the IMF, Chief of Staff for the Managing Director, and Head of Country Operations in Africa amongst others, and held a senior academic appointment. In these roles, he has spearheaded efforts to reform the international monetary and financial system, integrating advances in technology with finance with a focus on the governance of the system; shaped institutional responses to mitigate the adverse impact emanating from the global commons (pandemic and climate); led the strategy and design of global financial architecture; directed policy and lending operations; managed a large international Board of Directors; implemented deep institutional restructuring; headed multibillion dollar investment committees;  and, managed billion-dollar budgets. With substantial global experience in Asia, Mr. Tiwari has led engagements between and within international bodies (G-7 and the G20), public sector, think tanks, philanthropies, civil society, financial markets, media, and academia.
            Mr. Tiwari holds degrees in Economics from the University of Chicago, the London School of Economics and the University of Delhi.`,
        },
        {
            name: "Jean-Claude Trichet",
            description: `Jean-Claude Trichet was elected chairman of the French "Académie des Sciences Morales et Politiques" (2023). He is honorary
            chairman of the Board of Directors of the Bruegel Institute (Brussels), honorary chairman of the Group of Thirty (Washington)
            and former European chairman of the Trilateral Commission.
            Jean-Claude Trichet was President of the European Central Bank (2003-2011). He was Governor of Banque de France (1993-
            2003) and Undersecretary of the French Treasury (1987- 1993). He was President of the Paris Club (debt rescheduling) (1985-
            1993), President of the European Monetary Committee (1992-1993), President of the Group of 10 Central Banks Governors
            and President of the Global economy meeting in Basel (2002-2011). He was President of Sogepa (Société de Gestion des
            Participations Aéronautiques) (2012-2013) and Director of Airbus Group (2012-2018). He was named "Person of the Year"
            by the Financial Times in 2007 and n° 5 of the "World Most Powerful" in the Newsweek list (2008).
            Born in Lyon in 1942, Jean-Claude Trichet is an honorary inspecteur général des Finances and ingénieur civil des Mines. He
            is a graduate of the Institut d'études politiques de Paris, of the Université de Paris (in economics) and of the École Nationale
            d'Administration. Jean-Claude Trichet has been awarded honorary doctorates by several universities.`,
        },
        {
            name: "Paul Tucker ",
            description: `Sir Paul Tucker is a research fellow at the Mossavar-Rahmani Center for Business and Government at the Harvard Kennedy School, writing at the intersection of political economy and political theory. He is author of Global Discord (Princeton University Press, 2022) and Unelected Power (PUP, 2018). From 1980 to 2013, he was a central banker.`,
        },
        {
            name: "Julián <br/> Ventura",
            description: `Ambassador Julián Ventura is a career diplomat, currently on leave from the Mexican Foreign Service. With over 33 years in public service, he has held senior diplomatic positions in four administrations, most recently as Deputy Secretary of Foreign Affairs, where he oversaw key relationships in Asia, Europe, Africa, and the Middle East, and served as Mexico’s G-20 Sherpa. Previously, he served as Mexico´s Ambassador to China and the United Kingdom, and as Deputy Chief of Mission in the Embassy to the United States.  
            As Under Secretary for North America,  he played a central role managing the economic, trade, border, and immigration agendas with the United States and Canada. As Director-General for Asia-Pacific Affairs, he was closely involved in the Mexico-South Korea 21st Century Commission, the Mexico-Japan FTA negotiations, and five Asia-Pacific Economic Cooperation (APEC) Summits. Earlier in his career, he served as the Foreign Secretary´s Chief of Staff and in Mexico´s missions in Cuba, the United Nations-Vienna, and the Organization of American States.
            He is a frequent media analyst on global affairs in Mexican and international media and a participant in global policy forums, including the Doha Forum, the Munich Security Conference, the Chatham House London Conference, the Halifax Security Forum, the Atlantic Strategy Group and the Raisina Dialogue. He is also a founding member of the Mexican Council on Foreign Relations (COMEXI).
            He currently is a Senior Advisor at Albright Stonebridge Group, part of Dentons Global Advisors, and an Associate Fellow in Chatham House´s U.S. and the Americas Programme.`,
        },
        {
            name: "Margrethe Vestager",
            description: `Margrethe Vestager is the Executive Vice President of the European Commission for a Europe fit for the Digital Age and Commissioner for Competition.
            She previously served as Minister for Economic Affairs and the Interior (2011-14) and Minister for Education (1998-2001) of Denmark, and as President of the ECOFIN Council (2012). She was Political leader of the Danish Social Liberal Party (2007-14), and has worked for the Danish Ministry of Finance (1993-95). Ms. Vestager holds an MSc in Economics (University of Copenhagen).`,
        },
        {
            name: "Axel Weber",
            description: `Axel Weber is President of the Center for Financial Studies at Goethe University Frankfurt. He is the Chairman of the VISA Economic Empowerment Institute, Chairman of the Advisory Board of RAISIN GmbH, Chairman of the Trilateral Commission Europe and a Member of the Group of Thirty. He also is a Member of the TEMASEK International Advisory Council and an Senior Advisor to both CVC Advisors Limited and Boston Consulting Group.
            From 2012 to 2022, Axel Weber served as the Chairman of the Board of Directors of UBS Group AG. He was also Chairman of the Board of Directors of the Institute of International Finance.
            Prof. Weber was the President of the Deutsche Bundesbank (2004-2011) and served as a Member of the Governing Council of the European Central Bank, Member of the Board of Directors of the Bank for International Settlements, German Governor of the International Monetary Fund and was a member of the G7 and G20 Ministers and Governors. He was a member of the steering committees of the European Systemic Risk Board (2011) and the Financial Stability Board (2010-2011).
            He was Visiting Professor at the University of Chicago Booth School of Business (2011-2012) and Professor of International Economics at the University of Cologne (2001-2004). He also served as a Member of the German Council of Economic Experts (2002-2004). Before that, he was Professor of Monetary Economics and Director of the Center for Financial Studies at Goethe University in Frankfurt (1998-2001). He was also Professor of Economic Theory at the University of Bonn (1994-1998).
            Axel Weber holds a PhD in economics from the University of Siegen. He graduated with a master’s degree in economics at the University of Constance and holds honorary doctorates from the universities of Duisburg-Essen and Constance.`,
        },
        {
            name: "Martin Wolf",
            description: `Martin Wolf is Associate Editor and Chief Economics Commentator at the Financial Times, London. He was awarded the CBE (Commander of the British Empire) in 2000 for services to financial journalism. He was a member of the UK government’s Independent Commission on Banking between June 2010 and September 2011. 
            Mr Wolf has honorary doctorates from the London School of Economics, Nottingham University, Warwick University and Kingston University, in the UK, Macquarie University, in Australia and KU Leuven, in Belgium. He is an honorary fellow of Corpus Christ College and Nuffield College, Oxford University, and of King’s College, London.
            Mr Wolf was joint winner of the Wincott Foundation senior prize for excellence in financial journalism for 1989 and 1997. He won the “Accenture Decade of Excellence” at the Business Journalist of the Year Awards of 2003, “Commentator of the Year” award at the Business Journalist of the Year Awards of 2008, the Ludwig Erhard Prize for economic commentary for 2009 and “Commentariat of the Year 2009” at the Comment Awards, sponsored by Editorial Intelligence. He was placed in Foreign Policy’s list of the “Top 100 Global Thinkers” in 2009, 2010 and 2011. He was joint winner of the 2009 award for columns in “giant newspapers” at the 15th annual Best in Business Journalism competition of The Society of American Business Editors and Writers.  He won the 33rd Ischia International Journalism Prize in 2012, the Overseas Press Club of America’s prize for “best commentary on international news in any medium” for 2013 and the 2019 Lifetime Achievement Award at the Gerald Loeb Awards.
            Mr Wolf’s most recent publication is The Crisis of Democratic Capitalism (London and New York: Allen Lane, 2023).`,
        },
        {
            name: "Lanxin Xiang",
            description: `Dr. Lanxin Xiang is Distinguished Fellow at Henry Stimson Center in Washington, DC. He is Professor Emeritus of International History and Politics at the Graduate Institute of International and Development Studies (IHEID), Geneva.
            He is the Founding Director of the Institute of Security Policy (ISP), China National Institute for SCO International Exchange and Judicial Cooperation (CNISCO), Shanghai, PRC, and Chair of Security Policy at Shanghai University of Political Science and Law (SHUPL). He has founded a track II dialogue forum named “Strategic Compass Dialogues” (SCD), which mainly engages in policy elite dialogues between China and the US, China and EU, and China and Russia. 
            Prof. Xiang currently serves as a visiting scholar at the Belfer Center and visiting fellow at the Robert Schuman Center at EUI in Florence. He is also a senior advisor at the Club of Three based in London. He is a member of the Academic Council at the Valdai Discussion Club in Sochi, Russia. Before moving to Geneva in 1996, Prof. Xiang was an associate professor at Clemson University in South Carolina. He held an Olin Fellowship at Yale University, MacArthur Fellowship in Germany and Truman Fellowship from the Truman Library. 
            He was the 3rd Henry A. Kissinger Chair of Foreign Policy and International Affairs at the Library of Congress. He has also held distinguished chairs at Fudan University, East China Normal University and Foreign Affairs University, CFAU, Beijing. And he was a Senior Associate at CSIS, and a senior fellow at the Transatlantic Academy of the German Marshall Fund. He had collaborated with the National Intelligence Council (NIC) many times for Global Trend conference series in China, Geneva and Washington.
            A contributing editor at Survival, IISS, Prof. Xiang wrote comments on The Washington Post (U.S.A.), Der TagesSpiegel (Germany), South China Morning Post (HK), United Morning News (Singapore) and Global Times (China). A graduate from Fudan University in Shanghai, he has MA and PhD in European Studies from SAIS, Johns Hopkins University.
            Prof. Xiang’s primary research interests are Chinese history and politics and China’s relations with the US and Europe. He has published four books in English and six books in Chinese. His latest book, The Quest for Legitimacy in Chinese Politics—A new interpretation, is published in December 2019.`,
        },
        {
            name: "Maha Yahya",
            description: `Maha Yahya is director of the Malcolm H. Kerr Carnegie Middle East Center, where her work focuses broadly on political violence and identity politics, pluralism, development and social justice after the Arab uprisings, the challenges of citizenship, and the political and socio-economic implications of the migration/refugee crisis.

            Prior to joining Carnegie, Yahya led work on Participatory Development and Social Justice at the United Nations Economic and Social Commission for Western Asia (UN-ESCWA). She was previously regional adviser on social and urban policies at UN-ESCWA and spearheaded strategic and inter-sectoral initiatives and policies in the Office of the Executive Secretary which addressed the challenges of democratic transitions in the Arab world. Yahya has also worked with the United Nations Development Program in Lebanon, where she was the director and principal author of The National Human Development Report 2008–2009: Toward a Citizen’s State. She was also the founder and editor of the MIT Electronic Journal of Middle East Studies. 
            
            Yahya has worked with international organizations and in the private sector as a consultant on projects related to socioeconomic policy analysis, development policies, cultural heritage, poverty reduction, housing and community development, and postconflict reconstruction in various countries including Lebanon, Pakistan, Oman, Egypt, Jordan, Saudi Arabia, and Iran. She has served on a number of advisory boards including the MIT Enterprise Forum of the Pan Arab Region and the Lebanese Center for Policy Studies.
            
            Yahya  is the author of numerous publications, including most recently Unheard Voices: What Syrian Refugees Need to Return Home (April 2018); The Summer of Our Discontent: Sects and Citizens in Lebanon and Iraq (June 2017); Great Expectations in Tunisia (March 2016); Refugees and the Making of an Arab Regional Disorder (November 2015); Towards Integrated Social Development Policies: A Conceptual Analysis (UN-ESCWA, 2004), co-editor of Secular Publicities: Visual practices and the Transformation of National Publics in the Middle East and South Asia (University of Michigan Press, 2010) and co-author of Promises of Spring: Citizenship and Civic Engagement in Democratic Transitions (UN-ESCWA, 2013). `,
        },
        {
            name: "Minghao Zhao",
            description: `Dr. Minghao ZHAO is Professor & Deputy Director of Center for American Studies at Fudan University and China Forum Expert. Prior to joining Fudan, he served as a senior fellow and deputy director for strategic studies at China Center for Contemporary World Studies, the in-house think tank of International Department of the CPC’s Central Committee. His research focuses on Sino-US relations and international security. He is author of Strategic Restraint: Is New-type Sino-US Relationship Possible and The Belt & Road Initiative and China’s Connectivity-oriented Global Diplomacy. He has authored over 60 peer-reviewed scholarly articles and chapters in books. 
            He is frequently consulted by China government and. the policy reports written by him have informed Chinese top leadership. He has contributed policy papers for Center for American Progress, Carnegie Endowment for International Peace, US National Bureau of Asian Research, and Italian Institute of International Affairs among other research institutions. His opinion pieces have been featured in People's Daily, New York Times, Project Syndicate, Nikkei Asian Review, The Diplomat among other media outlets. His views on international affairs have been quoted in Washington Post, Financial Times, Les Echos, NHK, and other prominent outlets.
            He has participated in international research projects, such as Global Trends and China-US Relations, a project co-sponsored by the Atlantic Council and China Institute for International Studies. He has been invited to speak at international conferences, including World Peace Forum (Tsinghua University), Beijing Forum (Peking University), Xiangshan Forum (China Acadamy of Military Science), Global Strategic Review Conference (International Institute for Strategic Studies, UK), Atlantic Dialogue (German Marshall Fund, US), The Rhodes Forum (The Dialogue of Civilizations Research Institute, Russia), and Asan Beijing Forum (Asan Institute for Policy Studies, ROK). He is Non-resident Senior Fellow at the Institute of International and Strategic Studies, Peking University and the Center for International Security and Strategy, Tsinghua University. 
            He received a Ph.D. in International Relations, a MA in Diplomatic History, and a BA in Art Studies from Peking University. He also earned a LL.M in International Human Rights Law from a joint program by Lund University, Sweden and Peking University. He has held visiting scholarships in the United States, Italy, Japan, and Taiwan. `,
        },
    ];

    return (
    <div className={"dashboard"}>
        <Header back={true}/>
        {
        <>
        {
            (speaker)?
            <div className={(speaker && speaker.name) ? "pop-up blur" : 'pop-up z-index-negatif'} onClick={()=>{setSpeaker({index: speaker.index})}}>
                
            </div>
            :
            ''
        }
        <h2 className="mt-5 text-center">
            Speakers
        </h2>
        {
            (loading)?
            <h1 className="mt-5 text-center">
                Loading...
            </h1>
            :
            ''
        }
        <div className="pop-up-container position-fixed" style={(speaker && speaker.name) ? {opacity: 1, zIndex: 20} : {opacity: 0, zIndex: -5}}>
            <button onClick={()=>{setSpeaker({index: speaker.index})}} className="p-1 btn-speaker">
                X
            </button>
                <img 
                    src={(speaker) ? `./assets/speakers/${(speaker.index - 1)}.jpg` : ''} 
                    onError={(e) => e.target.src = './assets/speakers/default.jpg'} 
                    width="100%" 
                    height="auto" 
                    alt={(speaker) ? `Image of ${speaker.name}` : ''}
                />
                <h3 dangerouslySetInnerHTML={(speaker) ? {__html: speaker.name} : {__html: ''}} className="text-center mt-3">
                </h3>
                <p className="mt-3">
                    { (speaker) ? speaker.description : ''}
                </p>
        </div>
        <div className="container mt-3" style={(loading) ? {opacity: 0} : {opacity: 1}}>
            <div className="row">
                {
                    speakers.map((speaker, index) => {

                        const defaultImage = './assets/speakers/default.jpg';
                        const speakerImage = `./assets/speakers/${(index-1)}.jpg`;
                    
                        return (
                            <div key={index} className="col col-4 p-3">
                                <div className="speaker-card c-p" onClick={()=>{setSpeaker({...speaker, index: index})}}>
                                    <div className="image-container">
                                        <img 
                                            src={speakerImage} 
                                            onError={(e) => e.target.src = defaultImage} 
                                            width="100%" 
                                            height="auto" 
                                            alt={`Image of ${speaker.name}`}
                                        />
                                    </div>
                                    <h4 dangerouslySetInnerHTML={{__html: speaker.name}} className="text-center fs-1em mt-3 mb-3">
                                        
                                    </h4>
                                </div>
                            </div>
                        );
                    })                
                }
            </div>
        </div>
        </>
        }
        <FooterPartenerCommun/>
    </div>
    );
}