import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './components/login/Login';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Program from './components/program/Program';
import Speakers from './components/speakers/Speakers';
import List from './components/list/List';
import Contact from './components/contact/Contact';
import Partners from './components/partners/Partners';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' Component={Login}/>
        <Route path='/' Component={Login}/>
        <Route path='/dashboard' Component={Dashboard}/>
        <Route path='/program' Component={Program}/>
        <Route path='/speakers' Component={Speakers}/>
        <Route path='/list' Component={List}/>
        <Route path='/contact' Component={Contact}/>
        <Route path='/partners' Component={Partners}/>
      </Routes>
    </Router>
  );
}

export default App;
