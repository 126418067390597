import { useEffect, useState } from 'react'
import FooterPartenerCommun from '../commun/FooterPartenerCommun'
import Header from '../commun/Header'

export default function(){

    const dates = [
        'Friday 21',
        'Saturday 22',
        'Sunday 23',
    ];

    const [day, setDay] = useState(0);

    const program = [
        [
            {
                hours: `07:30 – 08:30`,
                program: {
                    title: 'Chairmen’s Meeting (for Trilateral Leadership only)',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Level 1 - Empire`,
            },
            {
                hours: `11:30 – 13:00`,
                program: {
                    title: 'Welcome Buffet Lunch',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Library, Foyer & Café Wiltcher`,
            },
            {
                hours: `13:00 – 13:05`,
                program: {
                    title: 'Welcome by Thomas Leysen',
                    description: `Chairman of the Belgian Group
                    and Host Chair of the Plenary Meeting `,
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: `13:05 – 13:20`,
                program: {
                    title: 'Opening Speech by His Majesty King Philippe',
                    description: ``,
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Groundfloor  - Ballroom`,
            },
            {
                hours: `13:20 – 13:30`,
                program: {
                    title: 'Official Opening by the Regional Chairs',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        new Speaker('Meghan L. O’Sullivan', 'North American Chair'),
                        new Speaker('Takeshi Niinami', 'Asia Pacific Chairman'),
                        new Speaker('Axel A. Weber', 'European Chairman'),
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: `13:30 – 14:30`,
                program: {
                    title: 'Panel 1: Ukraine and the Future of European Security',
                    description: `With America’s refocusing on the Asia-Pacific region to contain China, its additional obligation to protect
                    Israel against Hamas and other proxies of Iran, Europeans may have to wake up to the reality that Russia’s
                    aggression will increasingly be the Old Continents’ threat to cope with. Can Ukraine nevertheless win
                    the war against Russia? What would a Russian victory mean for European security and NATO?`,
                    moderators:[
                        new Moderator('Karen Donfried', `Senior Fellow, Belfer Center for Science and International Affairs, Kennedy School
                        of Government, Harvard University; former US Assistant Secretary of State for European and Eurasian Affairs`),
                    ],
                    speakers:[
                        
                    ],
                    others: [
                        new Other('Political-military Opening Statement by Rob Bauer', ' Chair of the Military Committee of NATO'),
                        new Other('Military-operational Opening Statement by Christopher Cavoli,', `Supreme Allied
                        Commander Europe and Commander, US European Command`),
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: `14:30 – 15:00`,
                program: {
                    title: 'Coffee Break',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Groundfloor - Corridor + Foyer`,
            },
            {
                hours: `15:00 – 16:00`,
                program: {
                    title: 'Panel 2: Geo-economic Rivalry and its Risks',
                    description: `International conflicts are increasingly being fought by geo-economic means. The economy is no longer
                        the goal, but the means to a geostrategic purpose. Economy is used as a weapon in this geo-economic
                        rivalry. Above all, the US and China compete for economic, political, and military dominance. Businesses
                        (especially in Europe and Asia) are increasingly caught in the crosshairs and risk becoming the collateral
                        damage of this historic dispute. How fundamental is this shift – does it lead to more deglobalization?
                        How can policy-makers and companies deal with it?`,
                    moderators:[
                        new Moderator('Alicia Garcia Herrero', 'Senior Fellow at Bruegel and Chief Economist, Asia Pacific at Natixis'),
                    ],
                    speakers:[
                        new Speaker('Julia Friedlander', `CEO, Atlantik-Brücke; former Director for the European Union, Southern Europe,
                                    and Economic Affairs at the US National Security Council, The White House `),
                        new Speaker('Kurt Lauk', `President and founder of Globe Capital Partners; former Member of the European Parliament
                                    (EPP Group-CDU); former President, Economic Council of the CDU Party; former Member of the Board,
                                    DaimlerChrysler`),
                        new Speaker('Richard Falkenrath', `Senior Fellow for National Security at the Council on Foreign Relations;
                                    former Head of Geopolitics at Bridgewater Associates`)
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: `16:00 – 17:00`,
                program: {
                    title: 'Panel 3: Addressing the Nationalist Tide. From Trump to the PVV in The Netherlands to the Elections for the European Parliament',
                    description: `Nationalism is gaining ground in Western politics. What could a more right-wing populist Europe mean?
                        Where does it come from? What has been the impact of nationalism so far? Do we risk a clash between
                        natives and immigrants due to failed integration?`,
                    moderators:[
                        new Moderator('Monica Maggioni', 'Editorial Director of Information, Rai; European Deputy Chair, Trilateral Commission'),
                    ],
                    mores:[
                        new Other('Opening Keynote: Koen Lenaerts ', `President of the Court of Justice of the EU, on Democracy
                            and the Rule of Law in an Age of Rising Nationalism`),
                    ]
                    ,
                    speakers:[
                        new Speaker('Magdalena Andersson', 'Leader of the Swedish Social Democratic Party and former Prime Minister of Sweden'),
                        new Speaker('Yasmine Kherbache', `, Judge of the Belgian Constitutional Court; former Member of Parliament,
                            and Chief of Staff of the Prime Minister of Belgium`),
                        new Speaker('Jane Harman', `Former Member of the US Congress and Chair, Commission on the National Defense
                            Strategy`),
                    ],
                    others: [
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: `17:00 – 18:00`,
                program: {
                    title: 'Panel 4: The Middle East: Prospects for Peace or Escalation',
                    description: `The Biden administration has been trying to avoid the war in Gaza to spiral into a regional war.
                        So far, Israel and the Lebanon-based militant group Hezbollah, one of Iran’s proxies, have kept their conflict
                        below the threshold of all-out war. Yet Iran’s mass ballistic missile and drone attack on Israel – and Israel’s
                        retaliation – raised anew the specter of a widening Middle East war that draws in Iran and its proxies.
                        What would be the geopolitical and economic consequences? What could be done to defuse tensions?`,
                    moderators:[
                        new Moderator('Laurence Deschamps-Laporte', `Research Director and Professor, CÉRIUM
                            (Montreal Centre for International Studies), Université de Montréal`),
                    ],
                    speakers:[
                        new Speaker('Philip Gordon', `Assistant to the President and National Security Advisor to the Vice President of
                            the United States`),
                        new Speaker('Maha Yahya', `Director of the Malcolm H. Kerr Carnegie Middle East Center;
                        Global Member of the Trilateral Commission`),
                        new Speaker('Omid Nouripour', `is a Member of the German Bundestag and Co-Leader of his party Bündnis 90/Alliance/The Greens`),
                    ]
                },
                room: `Groundfloor - Ballroom`,
            },
            {
                hours: '18:00 – 18:30',
                program:{
                    title: `Talk 6: Perspectives on Wars in Europe and the Middle East`,
                    description: `<strong>David Petraeus</strong>, Partner with the global investment firm KKR and the Chairman of the KKR Global
                        Institute; retired US Army General & former Director of the CIA, discusses with <strong>Richard Fontaine</strong>,
                        Executive Director, North America Group, The Trilateral Commission.`,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: `18:30 – 19:30`,
                program: {
                    title: 'Cocktail Reception ',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: `Steigenberger Wiltcher’s Hotel - Library & Foyer`,
            },
            {
                hours: `20:00`,
                program: {
                    title: 'Depart for National Groups’ Dinners with their Ambassadors',
                    description: '',
                    moderators:[

                    ],
                    speakers:[
                        
                    ]
                },
                room: ``,
            }
        ],
        [
            {
                hours: '08:00 – 09:15',
                program:{
                    title: `Regional Executive Committee Meeting (Breakfast; for ExCom Members only)`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                    others:[
                        new Other('Asia Pacific ', '<em style="color: #37598bbb">Level 1 - Empire</em>'),
                        new Other('North America ', '<em style="color: #37598bbb">Level 1 - Bellevue</em>'),
                        new Other('Europe ', '<em style="color: #37598bbb">Level 1 - Belvédère</em>'),
                    ]
                },
                room: 'Level 1 - Empire, Level 1 - Bellevue, Level 1 - Belvédère',
            },
            {
                hours: '09:30 – 10:00',
                program:{
                    title: `Talk 1: The European Project’s Momentum `,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                    others: [
                        new Other('René Moerland ', 'Publisher/Editor-in-Chief at Euractiv Media, '),
                        new Other('interviews Alexander De Croo ', `Prime Minister of Belgium
                                <br/>How can the European project gain momentum with the foreseen enlargement to 35+ members? To which
                                extent did Europe manage to improve its decision making process? Was the Belgian presidency a success?`)
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '10:00 – 10:30',
                program: {
                    title: 'Talk 2: The Future of Trilateral Relations',
                    description: `
                        <strong>Masahisa Ikeda</strong>, Asia Pacific Director, The Trilateral Commission; Global Co-Head of Sector Strategy, Co-Managing Partner of Japan & South Korea and Head of Tokyo, Allen Overy Shearman Sterling LLP, moderates a conversation between 
                        <strong>Ivo Daalder</strong>, CEO, Chicago Council on Global Affairs; former US Ambassador to NATO; former Director for European Affairs at the National Security Council, 
                        <strong>Siddharth Tiwari</strong>, Advisory Council of the Bretton Woods Committee, Washington, DC, Fellow at Chatham House, London, and Vice-President, International Finance Forum, Beijing, and 
                        <strong>Mario Monti</strong>, Member of the Italian Senate; WHO Special Envoy for Health, Finance and Sustainable Development for Europe and Central Asia; former President of the Council of Ministers, Italy; former Member of the European Commission, TC Honorary European Chairman`,
                    moderators: [],
                    speakers: [],
                    others: [
                        new Other('', 'Authoritarianism and protectionism are on the rise challenging the liberal rules-based world order. Can we establish global governance of the high seas, the deep-sea bed, the atmosphere, Antarctica and outer space – the traditional five “global commons” as defined by international law? What will be the future of democracy, liberty, and multilateralism between Europe, America, and Asia?'),
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '10:30 – 11:00',
                program:{
                    title: `Coffee Break`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[
                        
                    ],
                    others:[
                        
                    ],
                },
                room: 'Groundfloor - Corridor & Foyer',
            },
            {
                hours: '11:00 – 12:00',
                program:{
                    title: `Panel 5: The West and the Rest in a Changing World Order`,
                    description: `While the West has been inattentive to the Global South, China has, for better or worse, been filling
                            the void. Besides being the world’s top trader, China, mainly via its Belt and Road initiative, has become
                            the world’s largest official creditor, surpassing the loan books of the IMF, World Bank, and all other
                            22 Paris Club governments combined. Moreover, there are worrisome signs that the US has been moving
                            inexorably away from upholding a truly global liberal order, cutting off not just China and Russia but most
                            of the Global South which does not conform to “democratic norms”. Do labels such as the “West” or
                            “Global South” reflect today’s reality and global problems? Speakers from different parts of the world
                            present their perspectives on their preferred world order designs which tend to be disregarded in most
                            “Western” discussions.`,
                    moderators:[
                        new Moderator('Indrani Bagchi', `CEO, Ananta Centre, India`),
                        new Moderator('Scene Setter (DRF): Annahita Nikpour', ` Deputy European Correspondent, Ministry of Foreign
                            Affairs of Denmark`),
                    ],
                    speakers:[
                        new Speaker('Perspective from South America: Julián Ventura', `Senior Advisor, Albright Stonebridge Group;
                            Associate Fellow, Chatham House; former Mexican Deputy Foreign Secretary; former Ambassador
                            to China and the United Kingdom and Deputy Chief of Mission in the Embassy to the United States`),
                        new Speaker('Perspective from China: Xiang Lanxin', `Director of the Belt and Road Institute in Shanghai; Distinguished
                            Fellow, The Stimson Center, Washington, DC; Research Fellow, Belfer Center, Harvard University, Kennedy School`),
                        new Speaker('Perspective from Africa: Kofi Adjepong Boateng', `Founding Director, Pembani Remgro, UK-Ghana;
                            Research Associate, Centre for Financial History, University of Cambridge; Global Member of the TC`)
                    ],
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '12:00 – 14:00',
                program:{
                    title: `Lunch Break`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Groundfloor - Library, Foyer & Café Wiltcher',
            },
            {
                hours: '14:00 – 14:30',
                program:{
                    title: `Talk 3: (Digital) Currencies à la Carte: Dollar’s Relative Decline?`,
                    description: `<strong>Sigal Mandelker</strong>, General Partner at Ribbit Capital, former Under Secretary of the Treasury for
                    Terrorism & Financial Intelligence, and <strong>Jean-Claude Trichet</strong>, Vice-Chairman of the French Academy
                    of Moral and Political Sciences; former President of the European Central Bank; Honorary Governor of
                    the Banque de France; Honorary European Chairman, Trilateral Commission, <strong>moderated by Michael
                    Greenwald</strong>, North America Deputy Director, The Trilateral Commission; Global Head of Financial
                    Innovation and Digital Assets, Amazon Web Services; former US Treasury Attache to Qatar and Kuwait `,
                    moderators:[
                        
                    ],
                    speakers: [],
                    others:[
                        new Other('', `The so-called BRICS countries and, above all, Saudi Arabia, the world’s only swing producer in global oil
                        markets, are inclined to help China rewrite the rules of the global energy market as part of a larger effort
                        to “de-dollarize” the global economy in response to Washington’s use of the dollar and dollar foreign
                        exchange reserves as a weapon. To be sure, among the big four currencies – of the US, Europe, Japan
                        and the United Kingdom – “King Dollar” still claims the lion’s share of 60 percent of all foreign exchange
                        reserves – but this is the lowest level since 1995. Are we witnessing the dollar’s relative decline? Is it
                        time for central banks to work together through a network of central bank digital currencies, that would
                        decrease the US dollar’s domineering influence on trade worldwide and avoid another hegemonic reserve
                        currency like the Renminbi?`),
                    ]
                },
                room: 'Groundfloor - Ballroom)',
            },
            {
                hours: '14:30 – 15:00',
                program:{
                    title: `Talk 4: The Geo-politics of Monetary Policy`,
                    description: `A conversation between <strong>Paul Tucker</strong>, Senior Fellow at the Harvard Kennedy School; former Deputy
                        Governor of the Bank of England, and European Chairman <strong>Axel A. Weber, moderated by Martin
                        Wolf</strong>, Chief Economics Commentator, Financial Times`,
                    moderators:[

                    ],
                    speakers:[

                    ],
                    others:[
                        new Other('', `Inflation which was in no small part fueled by high energy prices already forced the US Federal Reserve
                            to slow down its “quantitative easing” and raise interest rates – moves which at the time were feared to
                            trigger a recession at best or a financial crisis at worst. While some see their view validated that inflation
                            was “transitory” after all, this expectation may be short-lived again in view of various geo-political risks
                            on the horizon.`),
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '15:00 – 15:30',
                program:{
                    title: `Coffee Break`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Groundfloor - Corridor & Foyer',
            },
            {
                hours: '15:30 – 16:30',
                program:{
                    title: `Talk 5: Europe’s Competitiveness in a Changing World Economy`,
                    description: `The Wall Street Journal’s Brussels Bureau Chief <strong>Daniel Michaels interviews Margrethe Vestager</strong>,
                        Executive Vice-President for A Europe Fit for the Digital Age and Competition, European Commission`,
                    moderators:[

                    ],
                    speakers:[

                    ],
                    others : [
                        new Other('', `The EU needs to better cope with the challenges posed by China, the United States and other regions that are
                            actively strengthening their industrial and technological position. Can Europe's competitiveness and reliance on
                            open, rules-based international trade be maintained, while its partners shift towards more protectionist policies?
                            What needs to be undertaken to improve the EU's position in a more competitive international environment?
                            Will the EU manage to use its own economic power more strategically to become a geopolitical player?
                            What is the takeaway from elections and the Draghi and Letta reports on competitiveness, and on the future
                            of the single market? What will be the big themes shaping the new leadership?`),
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '16:30 – 17:30',
                program:{
                    title: `Panel 6: Technology – At the Core of the US-Chinese Rivalry`,
                    description: `The new and emerging technologies – artificial intelligence (AI), robotics, Big Data, biotech, 3D printing,
                        the internet of things (IoT) – are considered as the drivers of economic growth and national strength in this
                        century, and all require cutting-edge semiconductors. US and Chinese leaders know that if their countries
                        are to continue to climb the economic value chain, producing cutting edge tech goods and services, it
                        needs the latest in chips technology. Making the chips race into the central core of the Sino-US struggle
                        puts more focus on Taiwan which has been a thorny problem even in less competitive times. Unlike
                        the economic sanctions, Taiwan is an issue over which Washington and Beijing cannot compromise.`,
                    moderators:[
                        new Moderator('David Sanger', 'White House and National Security Correspondent, The New York Times')
                    ],
                    mores : [
                        new Other('Scene Setter:', ` <strong>Edoardo</strong> Campanella, Senior Global Economist at UniCredit Bank and Research Fellow at the Harvard Kennedy School; DRF Alumni`),
                    ],
                    speakers:[
                        new Speaker('Taro Kono', `Minister for Digital Transformation, Japan; Member of the House of Representatives;
                            former Minister for Foreign Affairs and Minister of Defense `),
                        new Speaker('Bob Sternfels', 'Global Managing Partner of McKinsey & Company'),
                        new Speaker('Annet Aris', 'Senior Affiliate Professor of Strategy at INSEAD Business School and Board Member ASML, Randstad and Jungheinrich '),
                        new Speaker('Zhao Minghao', `Professor and Deputy Director, Center for American Studies at Fudan University,
                            China Forum Expert`),
                    ],
                    others :[
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '19:45',
                program:{
                    title: `Transfer to the Royal Fine Arts Museum`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: '3, rue de la Régence - 1000 Brussels',
            },
            {
                hours: '20:00 – 22:30',
                program:{
                    title: `Gala Dinner at the Royal Fine Arts Museum`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: '',
            },
        ],[
            {
                hours: '07:30 – 8:45',
                program:{
                    title: `Trilateral Executive Committee Meeting (Breakfast only for TC ExCom Members)`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Level 1 - Belvédère',
            },
            {
                hours: '09:00 – 10:00',
                program:{
                    title: `Panel 7: Foreign Policy Begins at Home: Rebooting Globalization 2.0 `,
                    description: `Thanks to globalization, more than a billion people have lifted themselves out of extreme poverty; it
                        yielded the growth of a large “global” middle-class outside the West. Yet in many Western countries public
                        attitudes regarding globalization have become polarized. Voters have grown skeptical of open markets.
                        While the former (and maybe next) US president Donald Trump is the most prominent, he is not the only
                        protagonist reflecting increasingly protectionist views. The EU and its member states also have to cope
                        with what many of their voters perceive to be the excesses of globalization and free markets.
                        How can we avoid a full-scale deglobalization and reboot globalization 2.0 instead?
                        Can we revive global cooperation, also by strengthening ties with countries of the Global South?`,
                    moderators:[
                        new Moderator('Nicholas D. Kristof', 'Columnist, New York Times'),
                    ],
                    speakers:[
                        new Speaker('Takeshi Niinami', `Asia Pacific Chairman, The Trilateral Commission; President and Chief Executive
                            Officer, Suntory Holdings Limited`),
                        new Speaker('Jean Charest', 'Former Premier of Quebec and former Deputy Prime Minister of Canada'),
                        new Speaker('Ine Marie Eriksen Søreide', `Member of Parliament for the Conservative Party of Norway (Høyre)
                            and Chair of the Standing Committee of Foreign Affairs and Defense; former Minister of Foreign Affairs;
                            former Minister of Defense`),
                    ],
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '10:00 – 10:30',
                program:{
                    title: `Talk 7: Call for Cooperation to Avoid a Climate Catastrophe`,
                    description: `A conversation with <strong>Dick Benschop</strong>, Interim-CEO of the Mission Possible Partnership;
                        former President & CEO, Royal Schiphol Group; former Deputy Foreign Minister of The Netherlands and <strong>Jason Bordoff</strong>, Founding Director of the Center on Global Energy Policy at Columbia University SIPA,
                        <strong>moderated by Meghan L. O’Sullivan</strong>, North American Chair of the Trilateral Commission,
                        Director of the Belfer Center for Science and International Affairs and Jeane Kirkpatrick Professor
                        of the Practice of International Affairs at Kennedy School of Government, Harvard University`,
                    moderators:[

                    ],
                    speakers:[

                    ],
                    others:[
                        new Other('Scene Setter, ' , `<strong>Dijana Galijašević</strong>: Founder & Co-CEO, Impact Hero`),
                        new Other('', `On the occasion of the presentation of the latest IPCC synthesis report, UN Secretary-General
                            <strong>António Guterres</strong> warned of a “climate time bomb ticking”. We are in a race against time. International
                            cooperation, policies and regulation, innovation and business investment should run together like
                            a clockwork. This is only partly the case. Important issues around societal equity are insufficiently
                            addressed. What is the state of play after COP28 and what needs to happen? And – will it happen?
                            Do we squander our last chance to counter climate change through global cooperation, thus endangering
                            the survival of numerous fragile states, and their economies?`),
                    ]
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '10:30 – 11:00',
                program:{
                    title: `Coffee Break`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Groundfloor - Corridor & Foyer',
            },
            {
                hours: '11:00 – 12:00',
                program:{
                    title: `An Exercise in Strategic Foresight with three parallel working groups`,
                    description: `organized by facilitators
                    (DRFs) and coached by <strong>Mathew Burrows</strong>, Program Lead and Distinguished Fellow at the Stimson
                    Center’s Strategic Foresight Hub; former Head of Strategic Foresight, US National Intelligence Council
                    Thinking in terms of political scenarios of current conflicts, one can better identify the adjustments that
                    need to be made in order to prevent potential escalations and achieve a more cooperative world order.
                    Each working group sketches one of three conceivable scenarios and identifies its drivers, potential
                    outcomes, and ways to cope with different futures:`
                }
            },
            {
                hours: '11:00 – 12:00',
                program:{
                    title: `Breakout 1: Trump 2.0 – Risks for Ukraine, NATO, and the Environment`,
                    description: `<strong>Facilitators: Jana Nelson</strong>, Senior Policy Advisor US Mission to the United Nations (NA DRF) and <strong>Emma Chang</strong>, ﻿Government Affairs Strategy Analyst, Washington, DC (AP DRF)`,

                },
                room: 'Groundfloor - Ballroom'
            },
            {
                hours: '11:00 – 12:00',
                program:{
                    title: `Breakout 2: Iran-Israel Regional Conflict`,
                    description: `<strong>Facilitators: Francesco Rocchetti</strong>, Secretary General, Italian Institute for International Political Studies (ISPI) (EUR DRF) and <strong>George Bogden</strong>, Olin Fellow, Columbia Law School (NA DRF)`,
                    moderators: [],
                    speakers: [],
                },
                room: 'Groundfloor - Foyer',
            },
            {
                hours: '11:00 – 12:00',
                program:{
                    title: `Breakout 3: US-China Tensions Escalating to an Open Conflict`,
                    description: `<strong>Facilitators: Mia Kovačić</strong>, Communications Director at CWP Global (EUR DRF) and <strong>Daisuke Kawai</strong>,Deputy Director and Project Assistant Professor, Economic Security Research Program, RCAST, University of Tokyo (AP DRF)
                            <strong>Facilitators report the key results of their working groups to the plenum</strong> to discuss recommendations for a viable, more cooperative world order.`,
                        moderators: [],
                        speakers: [],
                    },
                room: 'Level 1 - Bellevue '
            },
            {
                hours: '12:00 – 12:15',
                program:{
                    title:`<strong>Facilitators report the key results of their working groups to the plenum</strong> <span style="font-size: 0.8em; font-weight: normal;">to discuss recommendations for a viable, more cooperative world order.</span>`,
                    description: ``,
                    moderators: [],
                    speakers: [],
                },
                room: ''
            },
            {
                hours: '12:15 – 12:45',
                program:{
                    title: `Closing Reflection`,
                    description: `<strong>Facilitators report the key results of their working groups to the plenum</strong> to discuss recommendations for a viable, more cooperative world order.`,
                    moderators:[

                    ],
                    speakers:[
                        new Speaker('Alexia Bertrand', `State Secretary for the Budget and Consumer Protection, Belgium
                        Closing Remarks by the Conference Chairs`)
                    ],
                },
                room: 'Groundfloor - Ballroom',
            },
            {
                hours: '12:45 – 14:00',
                program:{
                    title: `Closing Lunch`,
                    description: ``,
                    moderators:[

                    ],
                    speakers:[

                    ],
                },
                room: 'Groundfloor - Library, Foyer & Café Wiltcher',
            },
        ]
    ];

    return(
        <div className="dashboard">
            <Header back={true}></Header>
            <h2 className='mt-4 text-center'>
                Program
            </h2>
            <div className='grid-display mt-3'>
                <div className='grid-program'>
                {
                    dates.map((date, index)=>{
                        return(
                            <div key={index} className={"p-1 bg-light"} onClick={()=>{setDay(index)}}>
                                <div className={(index == day) ? 'card-date-active' : 'card-date'}>
                                    {date}
                                </div>
                            </div>
                        );
                        })
                    }
                </div>
            </div>
            <div className='container'>
                {
                    program[day].map((prog, index)=>{
                        const hours = prog.hours.split(' – ');
                        return(
                            <div key={index} className={(index % 2) ? 'p-1 program bg-l' : 'mt-3 program bg-d'}>
                                <div className='text-center p-2'>
                                    <h6 className='start-h'>
                                        {hours[0]}
                                    </h6>
                                    <h6 className='end-h'>
                                        {hours[1]}
                                    </h6>
                                </div>
                                <div className='p-2'>
                                    <span style={{fontWeight: 'bold', fontSize: '1.4em'}} dangerouslySetInnerHTML={{__html:prog.program.title}}></span>
                                    <br />                                    
                                    {
                                        (prog.program.description && prog.program.description.length) ?
                                        <div>
                                            <br />
                                            <span dangerouslySetInnerHTML={{__html: prog.program.description}}>
                                        </span>   
                                        </div>
                                        :
                                        ''
                                    }
                                    <br />
                                    {(prog.program.moderators && prog.program.moderators.length) ? <><br /><span style={{fontWeight: 'bold', fontSize: '1.2em'}}>Moderator:</span><br /></> : ''}
                                    
                                    {
                                        prog.program.moderators && prog.program.moderators.map((mod)=>{
                                            return(
                                                <div>
                                                    <span style={{fontWeight: 'bold'}}>{mod.full_name}: </span>
                                                    <span>{mod.description}</span>
                                                    <br />
                                                    <br />
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        prog.program.mores ?
                                        prog.program.mores.map((more)=>{
                                            return (
                                            <div>
                                                <span style={{fontWeight: 'bold'}}>{more.title}</span>
                                                <span dangerouslySetInnerHTML={{__html:more.description}}></span>
                                                <br />
                                                <br />
                                            </div>)
                                        })
                                        :
                                        ''
                                    }
                                    {(prog.program.speakers && prog.program.speakers.length) ? <><span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{(prog.program.speakers.length > 1) ? 'Speakers:' : 'Speaker:'}</span><br /></> : ''}
                                    {
                                        prog.program.speakers && prog.program.speakers.map((speaker)=>{
                                            return(
                                                <div>
                                                    <span style={{fontWeight: 'bold'}}>{speaker.full_name}: </span>
                                                    <span>{speaker.description}</span>
                                                    <br />
                                                    <br />
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        prog.program.others ?
                                        prog.program.others.map((other)=>{
                                            return (
                                            <div>
                                                <span style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: other.title}}></span>
                                                <span dangerouslySetInnerHTML={{__html:other.description}}></span>
                                                <br />
                                                <br />
                                            </div>)
                                        })
                                        :
                                        ''
                                    }
                                </div>
                                <div className='p-2'>
                                    {prog.room}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <FooterPartenerCommun/>
        </div>
    )
}

class Speaker{
    constructor(full_name, description){
        this.full_name = full_name;
        this.description = description;
    }
}

class Moderator{
    constructor(full_name, description){
        this.full_name = full_name;
        this.description = description;
    }
}

class Other {
    constructor(title, description){
        this.title = title;
        this.description = description;
    }
}